import React from 'react';
import useFetch from 'services/hooks';
import Loader from 'components/Loader';
import { H5 } from 'components/Text';

const loaderStyle = {
  margin: '150px auto',
};

export default function InnerPosts({ inner, innerId, className }) {
  const [{ response, loading }] = useFetch(`${inner}/${innerId}`);

  if (loading) {
    return <Loader style={loaderStyle} />;
  }

  const post = response;

  return <H5 className={className}>{post.name || post.title.rendered}</H5>;
}
