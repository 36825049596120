import React from 'react';

const IconWebminar = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="100 100 800 800">
      <g>
        <path
          fill={fill}
          d="M734,272.5H266a16,16,0,0,0-16,16v70a4,4,0,0,0,4,4H746a4,4,0,0,0,4-4v-70a16,16,0,0,0-16-16m-439,60a15,15,0,1,1,15-15,15,15,0,0,1-15,15m45,0a15,15,0,1,1,15-15,15,15,0,0,1-15,15m45,0a15,15,0,1,1,15-15,15,15,0,0,1-15,15"
        />
        <path
          fill={fill}
          d="M746,392.5H254a4,4,0,0,0-4,4v290a16,16,0,0,0,16,16H598.76V639.43a34,34,0,0,1,51-29.45L750,667.86V396.5a4,4,0,0,0-4-4M575.94,554.43,444.06,630.57a8,8,0,0,1-12-6.93V471.36a8,8,0,0,1,12-6.93l131.88,76.14a8,8,0,0,1,0,13.86"
        />
        <path
          fill={fill}
          d="M737.07,695,634.76,636a4,4,0,0,0-6,3.47V757.57a4,4,0,0,0,7.46,2l20.15-34.9a4,4,0,0,1,6.93,0L688.92,769a4,4,0,0,0,5.46,1.46l21.07-12.17a4,4,0,0,0,1.47-5.46L691.3,708.5a4,4,0,0,1,3.47-6h40.3a4,4,0,0,0,2-7.46"
        />
      </g>
    </svg>
  );
}
export default IconWebminar;
