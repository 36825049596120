import React from 'react';
import { Button as ButtonUI } from 'antd';
import { colors } from 'pages/variables';

const stylesButton = {
  background: colors.primary,
  outline: 0,
  border: 0,
  lineHeight: 'normal',
  width: 'max-content',
};

const Button = ({ children, onClick, className, styles, htmlType, href }) => (
  <ButtonUI
    type="primary"
    onClick={onClick}
    style={{ ...styles, ...stylesButton }}
    className={className}
    htmlType={htmlType}
    href={href}
  >
    {children}
  </ButtonUI>
);

export default Button;
