import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useFetch from 'services/hooks';

import Loader from 'components/Loader';
import { H1, H5, P } from 'components/Text';
import ButtonLoad from 'components/Buttons/buttonLoad';
import Layout from 'components/Layout/layout';
import Button from 'components/Buttons';
import Card from 'components/CardInitiatives';

import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const GlobalInitiatives = styled.div`
  margin-bottom: 100px;
  hr {
    margin-bottom: 20px;
  }
  .ant-btn {
    &.no-active {
      background: white !important;
      color: #939597;
    }
  }
`;

const Section = styled.div`
  margin-bottom: 30px;
`;

const SectionHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 260px);
  grid-gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    display: block;
    .cards_inner {
      margin-right: 10px;
      margin-bottom: 10px;
      float: left;
      &:last-child {
        float: none;
      }
    }
  }
`;

const HRSlim = styled.hr`
  border: 0px;
  border-top: 1px solid #dcdcdd;
  margin-top: 20px !important;
`;

const h5 = {
  paddingBottom: '10px',
  background: '#f6f6f7',
};

export default function Index() {
  const history = useHistory();
  const LOAD_LEN = 3;
  const [visibleCardsCurrent, setVisibleCardsCurrent] = useState(LOAD_LEN);
  const [visibleCardsArchived, setVisibleCardsArchived] = useState({});
  const [openCurrent, setOpenCurrent] = useState(true);
  const [openArchived, setOpenArchived] = useState(false);
  const [dataC, setDataC] = useState([]);
  const [dataA, setDataA] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [years, setYears] = useState([]);
  const [{ response, loading }] = useFetch('initiatives?lxl_per_page=100');

  const date = new Date();
  const currentDate = date.getTime();
  const currentClick = () => {
    setOpenCurrent(true);
    setOpenArchived(false);
  };

  const archivedClick = () => {
    setOpenArchived(true);
    setOpenCurrent(false);
  };

  useEffect(() => {
    const currentDatee = new Date().getTime();
    const archivedFilter = response.filter(
      (post) => post.acf.expiry_date && new Date(post.acf.expiry_date).getTime() > currentDatee
    );
    const currentFilter = response.filter(
      (post) => post.acf.expiry_date && new Date(post.acf.expiry_date).getTime() < currentDatee
    );

    setDataA(currentFilter);
    setDataC(archivedFilter);
  }, [response]);

  useEffect(() => {
    const sorted = [...dataA].sort((a, b) => {
      const dateA = new Date(a.acf.expiry_date).getTime();
      const dateB = new Date(b.acf.expiry_date).getTime();
      return dateB - dateA;
    });

    setSortedData(sorted);
  }, [dataA]);

  useEffect(() => {
    const yearsArr = sortedData.map(({ acf }) => {
      return Number(new Date(acf.expiry_date).getFullYear());
    });

    const uniqueYears = [...new Set(yearsArr)];
    setYears(uniqueYears);

    const loadMoreArchive = uniqueYears.map((year) => {
      return {
        id: year,
        num: 3,
      };
    });

    const archiveObj = loadMoreArchive.reduce((acc, post) => {
      const { id, num } = post;
      return { ...acc, [id]: num };
    }, {});

    setVisibleCardsArchived(archiveObj);
  }, [sortedData]);

  const handleClickCurrent = () => {
    if (
      visibleCardsCurrent <
      response.filter((post) => new Date(post.acf.expiry_date).getTime() > currentDate).length
    ) {
      const more = response.filter((post) => new Date(post.acf.expiry_date).getTime() > currentDate)
        .length;
      setVisibleCardsCurrent(more);
    }
  };

  const handleClickArchived = (year, yearData) => {
    console.log(visibleCardsArchived, year, visibleCardsArchived[Number(year)]);
    const numberOfProfiles = yearData.length;
    const visibleCards = visibleCardsArchived[Number(year)];

    if (visibleCards < numberOfProfiles) {
      const more = numberOfProfiles - visibleCards;

      const newObj = {
        ...visibleCardsArchived,
        [year]: visibleCards + more,
      };

      setVisibleCardsArchived(newObj);
      console.log(visibleCardsArchived);
    }
  };

  const ArchivedCards = ({ year }) => {
    const yearData = sortedData.filter((post) => {
      return new Date(post.acf.expiry_date).getFullYear() === year;
    });
    const currentYear = new Date().getFullYear();

    return (
      <>
        <Collapse
          bordered={false}
          defaultActiveKey={currentYear}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="site-collapse-custom-collapse"
        >
          <Panel header={year} key={year} className="site-collapse-custom-panel">
            <H5 style={h5}>
              {!loading &&
                years.length > 0 &&
                `Viewing
                  ${
                    yearData.length <= visibleCardsArchived[year]
                      ? yearData.length
                      : visibleCardsArchived[year]
                  } of ${yearData.length} initiatives`}
            </H5>
            <WrapCards id="archivedCards">
              {loading && <Loader />}
              {yearData.slice(0, visibleCardsArchived[year]).map(
                (post) =>
                  new Date(post.acf.expiry_date).getFullYear() === year && (
                    <div className="cards_inner" key={post.acf.expiry_date}>
                      <div>
                        <div>
                          <Card
                            Title={post.title.rendered}
                            Subtitle={post.acf.subtitle}
                            Icon={post.acf.icon.url}
                            slug={post.slug}
                            onClick={() =>
                              history.push(`/global-initiatives/${post.id}`, {
                                from: `${post.id}`,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )
              )}
            </WrapCards>
            {!(visibleCardsArchived[year] >= yearData.length) && (
              <ButtonLoad onClick={() => handleClickArchived(year, yearData)} />
            )}
          </Panel>
        </Collapse>
      </>
    );
  };

  return (
    <Layout>
      <GlobalInitiatives>
        <Section>
          <SectionHead>
            <H1>Global initiatives</H1>
          </SectionHead>
          <P>
            Welcome to the initiatives section. From this page, you can explore the initiatives ran
            by the global team, as well as downloading the asset packs to help you roll out the
            events in your market.
          </P>
        </Section>
        <Section>
          <Button
            aria-expanded={openCurrent === true ? 'true' : 'false'}
            className={openCurrent ? '' : 'no-active'}
            onClick={currentClick}
          >
            Current initiatives
          </Button>

          <Button
            aria-expanded={openArchived === true ? 'true' : 'false'}
            className={openArchived ? '' : 'no-active'}
            onClick={archivedClick}
          >
            Archived initiatives
          </Button>
        </Section>

        {openCurrent === true && (
          <>
            <HRSlim />
            <H5 style={h5}>
              {!loading &&
                dataC.length > 0 &&
                `Viewing
                    ${
                      response.filter(
                        (post) => new Date(post.acf.expiry_date).getTime() < currentDate
                      ).length <= LOAD_LEN
                        ? LOAD_LEN
                        : visibleCardsCurrent
                    } of ${
                  response.filter((post) => new Date(post.acf.expiry_date).getTime() > currentDate)
                    .length
                } initiatives`}
            </H5>
            <>
              <WrapCards>
                {loading && <Loader />}

                {dataC &&
                  dataC.slice(0, visibleCardsCurrent).map((post) => (
                    <div className="cards_inner" key={post.title.rendered}>
                      <>
                        <Card
                          Title={post.title.rendered}
                          Subtitle={post.acf.subtitle}
                          Icon={post.acf.icon.url}
                          slug={post.slug}
                          onClick={() =>
                            history.push(`/global-initiatives/${post.id}`, { from: `${post.id}` })
                          }
                        />
                      </>
                    </div>
                  ))}
              </WrapCards>
              {!(
                visibleCardsCurrent >=
                response.filter((post) => new Date(post.acf.expiry_date).getTime() < currentDate)
                  .length
              ) && <ButtonLoad onClick={handleClickCurrent} />}
            </>
          </>
        )}

        {openArchived === true && (
          <>
            {years.map((year) => {
              return <ArchivedCards key={year} year={Number(year)} />;
            })}
          </>
        )}
      </GlobalInitiatives>
    </Layout>
  );
}
