import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import Layout from 'components/Layout/layout';
import { H1, P } from 'components/Text';
import ButtonAdd from 'components/Buttons/buttonAdd';
import { PlusOutlined } from '@ant-design/icons';

export default function ThankYou() {
  const history = useHistory();
  return (
    <Layout>
      <H1>Thank you!</H1>
      <P>
        The new profile has been submitted for review. Once approved, it will appear in the KEE
        directory. Profiles will typically be approved within 5 working days.
        <br />
        <br />
      </P>
      <Row>
        <Col span="8">
          <ButtonAdd
            onClick={() => history.push(`/kee-directory/add-profile`)}
            icon={<PlusOutlined />}
          >
            Add another profile
          </ButtonAdd>
        </Col>

        <Col span="8">
          <ButtonAdd
            style={{ paddingTop: '9px', outline: 'none' }}
            type="button"
            onClick={() => history.push(`/kee-directory`)}
          >
            Return to KEE directory
          </ButtonAdd>
        </Col>
      </Row>
    </Layout>
  );
}
