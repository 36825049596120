import React from 'react';
import styled from 'styled-components';

const LoaderUI = styled.div`
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #d91e49; /* brand */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function Loader({ style }) {
  return <LoaderUI style={style} />;
}
