import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

export const login = async () =>
  new Promise((resolve) => {
    window.location.href = `${REACT_APP_API_URL}/sso-login`;
    resolve();
  });

export const authenticate = async () => {
  try {
    return await axios.get(`${REACT_APP_API_URL}/sso-login/validate`, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
      withCredentials: true,
    });
  } catch (err) {
    return { status: 401 };
  }
};
