import React from 'react';

export default function IconClose() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#000"
      viewBox="0 0 24 24"
      stroke="#000"
      style={{ width: '20px', height: '20px' }}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 18L18 6M6 6l12 12"
        stroke="#000"
      />
    </svg>
  );
}
