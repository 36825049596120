import React, { useEffect, useState } from 'react';
import { Card as CardUI } from 'antd';
import styled from 'styled-components';
import useFetch from 'services/hooks';
import { H5 } from 'components/Text';
import { colors, sizes, fonts } from 'pages/variables';
import Button from 'components/Buttons';
import ButtonLoad from 'components/Buttons/buttonLoad';
import Loader from 'components/Loader';
import ProfPic from 'components/ProfilePic/profilePic';
import ProfilePic from '../../assets/img/profile.png';

const loaderStyle = {
  margin: '150px auto',
};

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 260px);
  grid-gap: 20px;
  justify-content: space-between;
  margin-top: 20px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    display: flex;
    flex-wrap: wrap;

  .ant-card {
    margin-right: 13px;
    margin-bottom: 13px;
  }
`;

const H6 = styled.h6`
  font-family: ${fonts.PlayFair};
  font-size: ${sizes.size24};
  padding-bottom: ${sizes.size10};
  color: ${colors.primary};
`;

const CardStyles = {
  display: 'flex',
  width: '260px',
  padding: 0,
  color: colors.text,
};

const CardBodyStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '100%',
};

const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Btn = styled.div`
  height: 100%;
  display: flex;
  align-items: end;
`;

export default function Team() {
  const LOAD_LEN = 3;
  const [{ response, loading }] = useFetch(
    `team?lxl_per_page=100&filter[orderby]=menu_order&order=desc`
  );
  const [visibleCards, setVisibleCards] = useState(LOAD_LEN);
  const numberOfProfiles = response.length;
  const noProfilesLeft = visibleCards >= numberOfProfiles;

  useEffect(() => {
    const numberOfEvents = response.length;
    const initialLen = numberOfEvents <= LOAD_LEN ? numberOfEvents : LOAD_LEN;
    setVisibleCards(initialLen);
  }, [response]);

  const handleClick = () => {
    const numberOfEvents = response.length;
    if (visibleCards < numberOfEvents) {
      const more = numberOfEvents - visibleCards;
      setVisibleCards((prevVisibleCards) => prevVisibleCards + more);
    }
  };
  if (loading) {
    return <Loader style={loaderStyle} />;
  }

  return (
    <div>
      <H5>{`Viewing ${visibleCards} of ${response.length} contacts`}</H5>
      <CardsWrapper>
        {response.slice(0, visibleCards).map((post) => {
          return (
            <CardUI style={CardStyles} bodyStyle={CardBodyStyles}>
              <WrapperInner>
                <div>
                  <ProfPic img={post.acf.picture.url || ProfilePic} width="74" height="74" />
                  <H6>{post.title.rendered}</H6>
                </div>
                <Btn>
                  <Button>
                    <a href={`mailto:${post.acf.email}`}>Send email</a>
                  </Button>
                </Btn>
              </WrapperInner>
            </CardUI>
          );
        })}
      </CardsWrapper>
      {!noProfilesLeft && <ButtonLoad onClick={handleClick} />}
    </div>
  );
}
