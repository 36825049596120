import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import Input from 'components/Input';
import Filter from 'pages/KeeDirectory/Filter';
import Callout from 'components/Callout';
import Button from 'components/Buttons';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  svg {
    width: 33px;
    height: 33px;
  }
`;

const Header = ({
  filterData,
  search,
  setSearch,
  loc,
  setLoc,
  spec,
  setSpec,
  local,
  events,
  setEvents,
  searchLoc,
  setSearchLoc,
  searchSpec,
  setSearchSpec,
}) => {
  const [calloutVisible, setCallOutVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setCallOutVisible(!calloutVisible)}>Filter</Button>
      {calloutVisible && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setCallOutVisible(false);
          }}
        >
          <Callout>
            <Filter
              local={local}
              setLoc={setLoc}
              loc={loc}
              setSpec={setSpec}
              spec={spec}
              events={events}
              setEvents={setEvents}
              filterData={filterData}
              searchLoc={searchLoc}
              setSearchLoc={setSearchLoc}
              searchSpec={searchSpec}
              setSearchSpec={setSearchSpec}
              onClose={() => setCallOutVisible(!calloutVisible)}
            />
          </Callout>
        </OutsideClickHandler>
      )}
      <HeaderWrapper>
        <Input
          placeholder="Search KEE directory"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </HeaderWrapper>
    </>
  );
};

export default Header;
