// / Codes for color palette
export const colors = {
  primary: '#d91e49',
  text: '#939597',
  lightGrey: '#f6f6f7',
  blue: '#1d93cd',
  green: '#11e58d',
  darkGreen: '#5c9424',
  white: '#fff',
};

// Sizes for typography scale
export const sizes = {
  size10: '10px',
  size12: '12px',
  size14: '14px',
  size16: '16px',
  size18: '18px',
  size20: '20px',
  size24: '24px',
  size28: '28px',
  size30: '30px',
  size32: '32px',
  size34: '34px',
  size35: '35px',
  size36: '36px',
  size42: '42px',
  size50: '50px',
};

export const fonts = {
  KumBold: 'KumbhSans-Bold',
  Kumbase: 'KumbhSans-Regular',
  PlayFair: 'PlayfairDisplay-Regular',
  PlayfairDisplayItalic: 'PlayfairDisplay-Italic',
  italic: 'PlayfairDisplay-Italic',
  InterBlack: 'Inter-Black',
  SpaceMono: 'SpaceMono-Regular',
};
