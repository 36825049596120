import * as React from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'pages/variables';
import Mundi from '../../assets/img/mundi.svg';

const ButtonMundi = styled.div`
  background: ${colors.blue};
  font-size: 14px !important;
  font-family: ${fonts.Kumbase};
  width: 80px;
  height: 32px;
  color: white;
  padding-left: 7px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  img {
    width: 20px;
    height: 20px;
  }
  span {
    margin-top: 5px;
    font-weight: normal;
    font-family: ${fonts.Kumbase}!important;
  }
`;

const ButtonBlue = ({ onClick }) => (
  <ButtonMundi onClick={onClick}>
    <img src={Mundi} alt="icon global" />
    <span>Global</span>
  </ButtonMundi>
);

export default ButtonBlue;
