import React from 'react';
import styled from 'styled-components';

const CalloutWrapper = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1;
`;

const CalloutArea = styled.div`
  background-color: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  border-radius: 2px;
  outline: transparent;
  width: 820px;
  padding: 20px 10px;
`;

const Triangle = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: inherit;
  border: inherit;
  box-sizing: border-box;
  transform: rotate(45deg);
  height: 16px;
  width: 16px;
  top: -6px;
  left: 20px;
`;

export default function Callout({ children }) {
  return (
    <CalloutWrapper>
      <Triangle />
      <CalloutArea>{children}</CalloutArea>
    </CalloutWrapper>
  );
}
