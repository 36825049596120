import { useEffect, useState } from 'react';
import getData from './api';

const parseQueryString = (query) => {
  const endpoint = query.split('?')[0];
  const outQuery = {};
  let queryText = query.split('?').slice(1);
  if (queryText && queryText.length > 0) {
    [queryText] = queryText;
    const queries = queryText.split('&');
    queries.forEach((q) => {
      const keyVal = q.split('=');
      if (keyVal.length === 1) {
        outQuery[keyVal[0]] = true;
      } else {
        outQuery[keyVal[0]] = keyVal[1]; // eslint-disable-line
      }
    });
  }
  return {
    endpoint,
    query: outQuery,
  };
};

const useFetch = (list, callback) => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const res = await getData(parseQueryString(list));
      setResponse(res);
      if (typeof callback === 'function') {
        callback();
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(list);
    // eslint-disable-next-line
  }, [list]);

  return [{ response, error, loading }, fetchData];
};

export default useFetch;
