import React from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import { colors, sizes, fonts } from 'pages/variables';
import { Card as CardUI } from 'antd';
import Button from 'components/Buttons';
import ProfPic from 'components/ProfilePic/profilePic';
import imageDefault from '../../assets/img/profile.png';

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 20px;
  img {
    max-width: 74px;
    // filter: grayscale(100%);
  }
  a {
    line-height: 2.5em !important;
  }
`;

const H6 = styled.h6`
  font-family: ${fonts.PlayFair};
  font-size: ${sizes.size24};
  padding-bottom: ${sizes.size10};
  color: ${colors.primary};
`;

const Title = styled.h3`
  font-family: ${fonts.KumBold};
  font-size: ${sizes.size24};
  color: ${colors.text};
`;

const CardStyles = {
  display: 'flex',
  width: '260px',
  padding: 0,
  color: colors.text,
};

const CardBodyStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '100%',
};


export default function ContactDetails({ name, email, image, contactText }) {
  return (
    <div>
      <Title>Contact</Title>
      {contactText && (
        <Text size={sizes.size16} style={{ paddingTop: sizes.size10 }}>
          {contactText}
        </Text>
      )}
      <CardsWrapper>
        <CardUI style={CardStyles} bodyStyle={CardBodyStyles}>
          <div>
            {image ? (
              <ProfPic img={image} width="74" height="74" />
            ) : (
              <ProfPic img={imageDefault} width="74" height="74" />
            )}
            <H6>{name}</H6>
            <Button href={`mailto:${email}`}>Send email</Button>
          </div>
        </CardUI>
      </CardsWrapper>
    </div>
  );
}
