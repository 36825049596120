import styled from 'styled-components';
import { colors, fonts } from 'pages/variables';

const EventCal = styled.div`
  position: relative;

  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .events {
    li {
      margin-top: 5px;
      min-height: 23px;
    }
  }

  .prev,
  .next {
    background: ${colors.white}!important;
    padding-left: 10px;
    padding-right: 10px;

    span {
      color: ${colors.text};
      font-size: 16px;
      text-shadow: none;
    }
  }

  .prev {
    margin-right: 7px;
  }
  .next {
    margin-left: 7px;
  }

  .disabled {
    opacity: 0.4;
  }

  .radio {
    svg {
      width: 31px;
      margin-top: -4px;
    }
    svg.iconList {
      width: 25px;
      margin-top: 5px;
    }

    .toggle {
      background: ${colors.white}!important;
      display: flex;
      align-itmes: center;

      svg {
        fill: ${colors.text};
        height: 30px;
        margin: 0;
        display: flex;
        align-items: center;
      }

      &.active {
        background: ${colors.primary} !important;
        align-items: center;
        svg {
          fill: ${colors.white};
        }
      }
    }
  }

  .h3 {
    color: ${colors.primary};
    padding: 0;
    line-height: 1;
    font-size: 22px;
    margin-top: 8px;
  }

  .host {
    font-family: ${fonts.PlayFair};
  }

  .date {
    font-family: ${fonts.SpaceMono};
    margin-bottom: 0 !important;
  }

  .location {
    margin-top: 5px;
  }

  .headerCal {
    padding: 20px 0 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f6f6f7;
    height: 30px;
    > div {
      height: 30px;
    }
  }

  .col {
    display: flex;
  }

  .col1 {
    width: 222px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .selectYear {
    width: 82px;
    height: 32px;
  }

  .selectMonth {
    width: 111px;
    height: 32px;
    border-color: ${colors.primary} !important;
  }

  .selectYear .ant-select-selector,
  .selectMonth .ant-select-selector {
    height: 100% !important;
  }

  .selectYear span,
  .selectMonth span {
    align-self: center !important;
  }

  .selectToggle {
    height: 32px;

    .ant-radio-button-wrapper {
      height: 32px;
      border-color: #d9d9d9 !important;
    }

    span {
      line-height: 32px;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: ${colors.primary} !important;
    }

    .ant-radio-button-wrapper:hover {
      color: ${colors.primary} !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before,
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      color: ${colors.primary} !important;
      background-color: #d9d9d9 !important;
    }
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
    .ant-picker-cell-inner.ant-picker-calendar-date {
      background: #fff;
    }

    .ant-picker-calendar-date-value {
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today {
    .ant-picker-cell-inner.ant-picker-calendar-date.ant-picker-calendar-date-today {
      border-color: #f0f0f0 !important;

      .ant-picker-calendar-date-value {
        color: #fff !important;
        background: #d91e49;
        width: 23px;
        height: 23px;
        text-align: center;
        line-height: 26px;
        border-radius: 100%;
        margin-left: auto;
        margin-bottom: 1px;
      }
    }
  }

  .ant-picker-calendar-date-today {
    border-color: #d91e49 !important;
  }

  .localglobal {
    position: relative;
    cursor: pointer;
  }

  .localGlobalCard {
    position: absolute;
    top: 40px;
    left: 0;
    width: 170px;
    z-index: 21;

    &:after {
      position: absolute;
      top: -10px;
      left: 35px;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #ffffff transparent;
    }

    &.local:after {
      left: 120px;
    }
  }

  .card {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 300px;
    background: #fff;
    z-index: 2;
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: 10px;
    }

    h5 {
      margin-bottom: 5px;
    }

    .ant-card-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: space-between;
    }

    .col {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .bottom {
      flex-direction: row;
      margin-top: auto;
    }

    &:before {
      content: '';
      position: absolute;
      left: -20px;
      top: 28%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 25px 37px 25px 0;
      border-color: transparent #ffffff transparent transparent;
    }
  }

  .ant-picker-calendar.ant-picker-calendar-full {
    overflow: hidden;
  }

  .ant-picker-cell-inner.ant-picker-calendar-date {
    margin: 0 !important;
  }

  .ant-picker-cell {
    overflow: hidden;
  }

  .ant-picker-cell-inner.ant-picker-calendar-date {
    overflow-x: hidden;
    overflow: hidden;

    :hover {
      overflow-y: auto;
    }

    -ms-overflow-style: auto; /* Internet Explorer 10+ */
  }
`;

export default EventCal;
