import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { isFuture } from 'date-fns';
import { Row, Col } from 'antd';
import useFetch from 'services/hooks';
import ButtonGlobal from 'components/Buttons/buttonGlobal';
import ButtonAdd from 'components/Buttons/buttonAdd';
import { H1, H3, P } from 'components/Text';
import Layout from 'components/Layout/layout';

import Directory from './Directory';
import Global from './Global';
import Header from './Header';

const KeeDirectory = styled.div`
  hr {
    margin-bottom: 20px;
  }
`;

const Section = styled.div`
  margin-bottom: 30px;
`;

const Filtering = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const HRSlim = styled.hr`
  border: 0px;
  border-top: 1px solid #dcdcdd;
  margin-top: 20px !important;
`;

const Index = () => {
  const [{ response, loading }] = useFetch('profiles?lxl_per_page=100');
  const [{ response: eventsRes }] = useFetch('events?lxl_per_page=100');
  const [filterData, setFilterData] = useState([]);
  const [search, setSearch] = useState('');
  const [searchLoc, setSearchLoc] = useState('');
  const [searchSpec, setSearchSpec] = useState('');
  const [local, setLocal] = useState([]);
  const [global, setGlobal] = useState([]);
  const [loc, setLoc] = useState({});
  const [spec, setSpec] = useState({});
  const [events, setEvents] = useState({});
  const [eventObj, setEventObj] = useState({});
  const history = useHistory();

  useEffect(() => {
    const localProfiles = response.filter(({ acf }) => {
      return acf.region?.name === 'Local';
    });
    const globalProfiles = response.filter(({ acf }) => {
      return acf.region?.name === 'Global';
    });

    setLocal(localProfiles);
    setGlobal(globalProfiles);
  }, [response]);

  useEffect(() => {
    const obj = {};

    eventsRes
      .filter(({ acf }) => {
        const dateS = acf.date.split('-');
        const date = new Date(`${dateS[2]}-${dateS[1]}-${dateS[0]}`);
        return isFuture(date);
      })
      .forEach(({ acf }) => {
        if (acf.event_relationship) {
          acf.event_relationship.forEach((id) => {
            if (obj[id]) {
              obj[id] += 1;
            }
            obj[id] = 1;
          });
        }
      });

    setEventObj(obj);
  }, [eventsRes]);

  return (
    <Layout>
      <KeeDirectory>
        <Section>
          <H1>KEE directory</H1>
          <P>
            Welcome to the KEE directory. From this page you can explore our directory of KEEs,
            their proﬁles and how to contact them.
          </P>
        </Section>

        <Section>
          <ButtonAdd
            onClick={() => history.push(`/kee-directory/add-profile`)}
            icon={<PlusOutlined />}
          >
            Add new profile
          </ButtonAdd>
        </Section>

        <Section>
          <Filtering>
            <Header
              local={local}
              loc={loc}
              setLoc={setLoc}
              spec={spec}
              setSpec={setSpec}
              events={events}
              setEvents={setEvents}
              setSearch={setSearch}
              search={search}
              filterData={filterData}
              searchLoc={searchLoc}
              setSearchLoc={setSearchLoc}
              searchSpec={searchSpec}
              setSearchSpec={setSearchSpec}
            />
          </Filtering>
          <HRSlim />
          <Directory
            locVal={loc}
            specVal={spec}
            searchVal={search}
            eventsVal={events}
            eventObj={eventObj}
            setFilterData={setFilterData}
            response={local}
            loading={loading}
            searchLoc={searchLoc}
            searchSpec={searchSpec}
          />
        </Section>

        <hr />

        <Section>
          <Row>
            <Col span={22} style={{ paddingRight: '150px' }}>
              <H3>Global KEEs</H3>
              <P>
                Here is the conﬁrmed list of KEEs who have been involved in initiatives delivered by
                the global team over the year.
              </P>
            </Col>
            <Col span={2}>
              <ButtonGlobal />
            </Col>
          </Row>
        </Section>

        <Section>
          <Global response={global} loading={loading} />
        </Section>
      </KeeDirectory>
    </Layout>
  );
};

export default Index;
