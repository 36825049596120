import React from 'react';
import imageDefault from '../../assets/img/profile.png';

const ProfPic = ({ img, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svgroot"
      viewBox="0 0 400 377"
      width={width}
      height={height}
    >
      <defs>
        <filter id="filtersPicture">
          <feComposite
            result="inputTo_38"
            in="SourceGraphic"
            in2="SourceGraphic"
            operator="arithmetic"
            k1="0"
            k2="1"
            k3="0"
            k4="0"
          />
          <feColorMatrix id="filter_38" type="saturate" values="0" data-filterid="38" />
        </filter>
      </defs>
      <image
        filter='url("#filtersPicture")'
        x="0"
        y="0"
        width="100%"
        height="100%"
        preserveAspectRatio="xMinYMin slice"
        xlinkHref={img || imageDefault}
      />
    </svg>
  );
};
export default ProfPic;
