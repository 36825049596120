import React from 'react';
import { Input as InputUI } from 'antd';

const Input = ({
  type,
  className,
  placeholder,
  value,
  onChange,
  onBlur,
  name,
  maxlength,
  max,
  min,
}) => (
  <InputUI
    type={type}
    className={className}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    name={name}
    id={name}
    maxLength={maxlength}
    max={max}
    min={min}
  />
);

export default Input;
