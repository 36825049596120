import React from 'react';
import styled from 'styled-components';
import { fonts, sizes, colors } from 'pages/variables';

const Text = ({ color, size, font, children, style }) => (
  <>
    <Paragraph color={color} size={size} font={font} style={style}>
      {children}
    </Paragraph>
  </>
);

const Paragraph = styled.p`
  color: ${(p) => p.color};
  font-size: ${(p) => p.size};
  font-family: ${(p) => p.font};
`;

export default Text;

const H1 = styled.h1`
  font-size: ${sizes.size42};
  font-family: ${fonts.InterBlack};
  padding-bottom: ${sizes.size10};
  color: ${colors.primary};
`;
H1.displayName = 'H1';

const H2 = styled.h2`
  font-size: ${sizes.size30};
  font-family: ${fonts.KumBold};
  line-height: 1.3em;
  padding-bottom: ${sizes.size10};
  color: ${colors.primary};
`;
H2.displayName = 'H2';

const H3 = styled.h3`
  font-family: ${fonts.KumBold};
  font-size: ${sizes.size24};
  padding-bottom: ${sizes.size10};
  color: ${colors.text};
`;
H3.displayName = 'H3';

const H4 = styled.h4`
  font-family: ${fonts.PlayfairDisplayItalic};
  font-size: ${sizes.size24};
  line-height: ${sizes.size28};
  padding-bottom: ${sizes.size10};
  color: ${colors.primary};
`;
H4.displayName = 'H4';

const H5 = styled.h5`
  font-size: ${sizes.size13};
  font-famly: ${fonts.Kumbase};
  color: ${colors.text};
  line-height: 1.3em;
  span {
    font-family: ${fonts.KumBold}!important;
  }
`;
H5.displayName = 'H5';

const P = styled.p`
  font-size: ${sizes.size16};
  line-height: 1.3em;
  max-width: 550px;
  margin-bottom: 1.3em;
`;
P.displayName = 'P';

export { H1, H2, H3, H4, H5, P };
