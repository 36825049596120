import React, { useState } from 'react';
import useFetch from 'services/hooks';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Form, Input, Radio, DatePicker, Upload, message } from 'antd';
import axios from 'axios';
import ls from 'local-storage';
import styled from 'styled-components';
import DropdownSelect from 'components/DropdownSelect/local';
import DropdownSelectXHR from 'components/DropdownSelect';
import SubHeader from 'components/SubHeader';
import Loader from 'components/Loader';
import Layout from 'components/Layout/layout';
import { P, H5 } from 'components/Text';
import { colors } from 'pages/variables';
import ButtonAdd from 'components/Buttons/buttonAdd';
import InnerPosts from 'components/InnerPosts';
import moment from 'moment';
import imageDefault from '../../assets/img/profile.png';

const { TextArea } = Input;

const { REACT_APP_CMS_URL } = process.env;
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY HH:mm';

const formStyles = {
  background: '#ffffff',
  padding: '50px 0px',
  marginTop: '30px',
};

const buttonStyles = {
  float: 'right',
};

const StyledForm = styled(Form)`
  .radioBtns {
    .ant-form-item-control {
      flex: auto;
    }
  }
  .ant-select-selection-item {
    color: #939597;
    // background: #e1e1e1 !important;
  }

  .ant-select-selector {
    border: solid 0.9px #939597 !important;
  }

  .ant-form-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 30px;
    label {
      font-family: KumbhSans-Bold;
      color: ${colors.text};
    }
  }
  input {
    background: ${colors.lightGrey};
    opacity: 0.6;
  }
  h5 {
    line-height: inherit;
  }
  .uploadImg {
    height: 300px;
    padding-bottom: 30px;
    border-radius: 4.6px;
    border: solid 0.9px #d9d9d9;
    background-color: rgba(229, 231, 235, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 99%;
      height: 99%;
    }
    div {
      width: 200px !important;
      height: 200px !important;
      margin: 0;
      padding: 0;
    }
    .ant-form-item-control {
      display: flex;
      flex-direction: column;
      flex-grow: inherit;
    }
    .ant-upload {
      background-color: #e5e7eb;
      font-family: KumbhSans-Bold;
      color: ${colors.text};
    }
    .text-upload {
      background: gray;
      width: 140px;
      border-radius: 30px;
      color: white;
      font-size: 14px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid white;
      padding-top: 4px;
    }
  }
`;

const ProfilePic = styled.div`
  width: 300px;

  img {
    width: 100px;
    border: solid 0.9px #d9d9d9;
    padding: 10px;
  }
`;

const HRSlim = styled.hr`
  border: 0px;
  border-top: 1px solid #dcdcdd;
  margin: 40px;
`;

const TimeZoneOptions = [
  'GMT-11 - America/Nome',
  'GMT-10 - Pacific/Honolulu',
  'GMT-9 - America/Anchorage',
  'GMT-8 - America/Tijuana',
  'GMT-7 - America/Phoenix',
  'GMT-6 - America/Winnipeg',
  'GMT-5 - America/Vancouver',
  'GMT-4 - Atlantic/Cape_Verde',
  'GMT-3 - America/Argentina/Buenos_Aires',
  'GMT-2 - Atlantic/Azores',
  'GMT-1 - Africa/Algiers',
  'GMT - Europe/London',
  'GMT+1 - Europe/Rome',
  'GMT+2 - Europe/Kaliningrad',
  'GMT+3 - Europe/Moscow',
  'GMT+4 - Asia/Yerevan',
  'GMT+5 - Asia/Karachi',
  'GMT+6- Asia/Omsk',
  'GMT+7 - Asia/Krasnoyarsk',
  'GMT+8 - Asia/Shanghai',
  'GMT+9 - Asia/Tokyo',
  'GMT+10 - Australia/Sydney',
  'GMT+11 - Asia/Sakhalin',
  'GMT+12 - Pacific/Auckland',
];

const loaderStyle = {
  margin: '150px auto',
};

const WrapUploadtext = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UploadContent = () => {
  return (
    <WrapUploadtext>
      <p className="text-upload">Upload an image</p>
      <p className="text-drag">or drag and drop</p>
    </WrapUploadtext>
  );
};

const EditEvent = function EditEvent({ match }) {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [fileList, setFileList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [{ response, loading }] = useFetch(`events/${match.params.id}`);

  const props = {
    name: 'file',
    multiple: false,
    onChange(info) {
      if (info.file.status === 'removed') {
        setFileList('');
        setSelectedFiles('');
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setFileList(info.fileList);
        setSelectedFiles(info.fileList[0].originFileObj);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const onFinish = (values) => {
    let startDate = '';
    let startTime = '';
    let endDate = '';
    let endTime = '';

    if (values.date) {
      const [start, end] = values.date;
      // eslint-disable-next-line
      const startd = new Date(start._d);
      // eslint-disable-next-line
      const endd = new Date(end._d);

      startDate = `${startd.getMonth() + 1}/${startd.getDate()}/${startd.getFullYear()}`;
      startTime = `${startd.getHours()}:${startd.getMinutes()}`;

      endDate = `${endd.getMonth() + 1}/${endd.getDate()}/${endd.getFullYear()}`;
      endTime = `${endd.getHours()}:${endd.getMinutes()}`;
    }

    const form = {
      status: 'draft',
      title: values.title,
      fields: {
        date: startDate,
        enddate: endDate,
        start_time: startTime,
        end_time: endTime,
        default_time_zone: values.defaulttimezone,
        description: values.description,
        location:
          typeof values.location === 'string'
            ? JSON.stringify(values.location).match(/\d+/g)
            : JSON.stringify(response.acf.location).match(/\d+/g),
        event_relationship: values.selectprofiles
          ? JSON.stringify(values.selectprofiles).match(/\d+/g)
          : '',
        time_zone: values.timezone,
        region: values.radioregion,
        category:
          typeof values.category === 'string'
            ? JSON.stringify(values.category).match(/\d+/g)
            : JSON.stringify(response.acf.category).match(/\d+/g),
        contact_name: values.contact_name,
        contact_email: values.contact_email,
      },
    };
    const instance = axios.create({
      baseURL: `${REACT_APP_CMS_URL}/wp-json/jwt-auth/v1/token`,
      headers: { Authorization: `Bearer ${ls.get('session_token')}` },
    });
    instance
      .post(`${REACT_APP_CMS_URL}/wp-json/wp/v2/events/${match.params.id}`, form)
      .then((res) => {
        const num = res.data.id;
        const n = num.toString();
        const photo = new FormData();
        photo.append('lxl_field_type', 'contact');
        photo.append('object_id', n);
        photo.append('file', selectedFiles);
        return axios.post(`${REACT_APP_CMS_URL}/wp-json/lxl/v1/file/upload`, photo);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('error', err);
      });
    setRedirect(true);
  };

  if (loading) {
    return (
      <Layout>
        <Loader style={loaderStyle} />
      </Layout>
    );
  }

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.error('Failed: ', errorInfo);
  };

  const post = response;

  return (
    <Layout>
      {redirect && (
        <>
          <Redirect to="/event-submitted" />
        </>
      )}
      <>
        <SubHeader breadcrumb={['Event calendar', 'edit event']} />
        <P>
          From this page you can add a new Event to the calendar. These will be reviewed and
          approved before appearing in the calendar. Once the event has been added, you and others
          will be able to search for and add them to future activities. Please complete the form and
          submit for review.
          <br />
          Events will typically be approved within 5 working days.
        </P>
        <StyledForm
          name="basic"
          initialValues={
            ({ remember: true },
            {
              title: post.title.rendered,
              description: post.acf.description,
              location: <InnerPosts inner="location" innerId={post.acf.location} />,
              date: [
                moment(`${post.acf.date} ${post.acf.start_time}`, dateFormat),
                moment(`${post.acf.enddate} ${post.acf.end_time}`, dateFormat),
              ],
              defaulttimezone: post.acf.default_time_zone.value,
              timezone: post.acf.time_zone,
              category: <InnerPosts inner="category" innerId={post.acf.category} />,
              radioregion: `${post.acf.region.term_id.toString()}`,
              contact_name: `${post.acf.contact_name}`,
              contact_email: `${post.acf.contact_email}`,
              selectprofiles: post.acf.event_relationship.map((profile) => (
                <InnerPosts inner="profiles" innerId={profile} />
              )),
            })
          }
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={formStyles}
        >
          <Form.Item
            labelAlign="left"
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Please add a title' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Description"
            name="description"
            message=""
            rules={[{ required: true, message: 'Please add a description' }]}
          >
            <TextArea placeholder={post.acf.description} />
          </Form.Item>

          <DropdownSelectXHR
            type="category"
            name="category"
            label="Engagement type"
            message="Please select an engagement type"
            placeholder="Please select an engagement type"
            single
          />

          <DropdownSelectXHR
            type="location"
            name="location"
            message="Please select a location"
            single
          />

          <HRSlim />

          <Form.Item labelAlign="left" label="Dates" name="date">
            <RangePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />
          </Form.Item>

          <HRSlim />

          <DropdownSelect
            options={TimeZoneOptions}
            name="defaulttimezone"
            label="Default time zone"
            message="Please select a default time zone"
            single
          />

          <DropdownSelect
            options={TimeZoneOptions}
            name="timezone"
            required={false}
            label="Please select up to three time zones that will be displayed for your event"
            message="Please select a time zone"
          />

          <HRSlim />

          <DropdownSelectXHR
            required={false}
            type="profiles"
            name="selectprofiles"
            label="KEEs involved"
            message="Please select a profile"
            placeholder="Select the KEEs involved"
          />

          <HRSlim />

          <Form.Item
            labelAlign="left"
            name="radioregion"
            label="Region"
            className="radioBtns"
            rules={[{ required: true, message: 'Select a region' }]}
          >
            <Radio.Group>
              <Radio value="9">
                Global:
                <H5>Global events are those organized and hosted by the Astellas global team</H5>
              </Radio>
              <Radio value="10">
                Local: <H5>Local events are those organized and hosted by local affiliate teams</H5>
              </Radio>
            </Radio.Group>
          </Form.Item>

          <HRSlim />

          <Form.Item
            labelAlign="left"
            label="Contact name"
            name="contact_name"
            rules={[{ required: true, message: 'Contact name is required' }]}
          >
            <Input placeholder="Type contact name here" />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Contact email"
            name="contact_email"
            rules={[{ required: true, message: 'Contact email is required' }]}
          >
            <Input placeholder="Type contact email here" />
          </Form.Item>

          <Form.Item labelAlign="left" name="picture" label="Profile picture">
            <ProfilePic>
              {post.acf.profile_picture ? (
                <img src={post.acf.profile_picture} alt="" />
              ) : (
                <img src={imageDefault} alt="" />
              )}
            </ProfilePic>
          </Form.Item>

          <Form.Item name="upload" valuePropName="file" className="uploadImg">
            <label htmlFor="basic_profileimage" className="ant-form-item-required" title="Image">
              Update contact profile image
            </label>
            <Upload
              {...props}
              name="logo"
              customRequest={dummyRequest}
              listType="picture-card"
              accept=".png, .jpg"
            >
              {fileList.length < 1 && <UploadContent />}
            </Upload>
          </Form.Item>

          <HRSlim />

          <Form.Item labelAlign="left">
            <ButtonAdd htmlType="submit" styles={buttonStyles}>
              Submit for review
            </ButtonAdd>
          </Form.Item>
        </StyledForm>
      </>
    </Layout>
  );
};

export default withRouter(EditEvent);
