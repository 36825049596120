import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Card from 'components/Card';
import Select from 'components/Select';
import Loader from 'components/Loader';
import ButtonLoad from 'components/Buttons/buttonLoad';
import { H5 } from 'components/Text';

const FilterByYear = styled.label`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  label {
    padding-right: 10px;
  }
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 260px);
  grid-gap: 20px;
  justify-content: space-between;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    display: block;
    > div {
      margin-right: 10px;
      margin-bottom: 10px;
      float: left;
      &:last-child {
        float: none;
      }
    }
  }
  + div {
    clear: both;
  }
`;

const HRSlim = styled.hr`
  border: 0px;
  border-top: 1px solid #dcdcdd;
  margin-top: 20px !important;
`;

const loaderStyle = {
  margin: '30px auto',
};

const h5 = {
  paddingBottom: '10px',
};

export default function Global({ response, loading }) {
  const LOAD_LEN = 3;
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [visibleCards, setVisibleCards] = useState(LOAD_LEN);
  const [options, setOptions] = useState([{ value: 'All', key: 'all' }]);
  const [selected, setSelected] = useState(null);
  const numberOfProfiles = response.length;
  const noProfilesLeft = visibleCards >= numberOfProfiles;

  useEffect(() => {
    const createdArray = response.map(({ date }) => {
      return date.split('-')[0];
    });
    const uniqueCreated = [...new Set(createdArray)];
    const createOptions = uniqueCreated.map((item) => {
      return {
        value: item,
        key: item,
      };
    });
    setOptions([...options, ...createOptions]);
    return setData(response);
    // eslint-disable-next-line
  }, [response]);

  useEffect(() => {
    if (selected) {
      if (selected === 'All') {
        setVisibleCards(response.length);
        return setData(response);
      }
      const filtered = response.filter(({ date }) => {
        const res = date.split('-')[0];
        return selected === res;
      });

      setVisibleCards(response.length);
      return setData(filtered);
    }

    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    const sorted = [...data].sort((a, b) => {
      const surnameA = a.title.rendered.split(' ');
      const surnameB = b.title.rendered.split(' ');
      return surnameA[surnameA.length - 1] > surnameB[surnameB.length - 1] ? 1 : -1;
    });

    setSortedData(sorted);
  }, [data]);

  const handleChange = (e) => {
    setSelected(e);
  };

  const handleClick = () => {
    if (visibleCards < numberOfProfiles) {
      const more = numberOfProfiles - visibleCards;
      setVisibleCards((prevVisibleCards) => prevVisibleCards + more);
    }
  };

  const showViewing = !loading && data.length > 0;

  return (
    <>
      <FilterByYear>
        <label htmlFor="globalYear">Select year:</label>
        <Select id="globalYear" options={options} onChange={handleChange} />
      </FilterByYear>
      <HRSlim />
      <H5 style={h5}>{showViewing && `Viewing ${visibleCards} of ${numberOfProfiles} profiles`}</H5>
      <CardsWrapper>
        {loading && <Loader style={loaderStyle} />}
        {sortedData.slice(0, visibleCards).map((props) => (
          <Card {...props} key={props.id} />
        ))}
      </CardsWrapper>
      {!noProfilesLeft && <ButtonLoad onClick={handleClick} />}
      <br />
    </>
  );
}
