import * as React from 'react';
import styled from 'styled-components';
import Button from '../../components/Buttons';
import ButtonGlobal from '../../components/Buttons/buttonGlobal';

import Text from '../../components/Text';
import IconCalendar from '../../components/Icons/icon-calendar';

import { colors, fonts, sizes } from '../variables';

const WrapStyles = styled.ul`
  width: 100%;
  padding: 50px;
`;

const Icons = styled.ul`
  width: 31px;
  height: 31px;
  border: 1px solid #939597;
`;

export default function Index() {
  return (
    <WrapStyles>
      <h1>H1: use for page titles</h1>
      <hr />
      <h2>H2</h2>
      <hr />
      <h3>H3</h3>
      <hr />
      <h4>H4: serif</h4>
      <hr />
      <p>Paragraph</p>
      <hr />
      <Text color={colors.primary} size={sizes.size36} font={fonts.Kumbase}>
        I am a custom paragraph
      </Text>
      <hr />

      <h2>Icons</h2>
      <h3>
        All icons are svg and in: <code>components/Icons/icon-name</code>
      </h3>
      <code>&lt;IconCalendar fill=&apos;#d00f69&ldquo;&gt;</code>
      <Icons className="wrap-svg">
        <IconCalendar fill="#d00f69" />
      </Icons>
      <br />
      <code>&lt;IconCalendar fill=&apos;#000&ldquo;&gt;</code>
      <Icons className="wrap-svg">
        <IconCalendar fill="#000" />
      </Icons>
      <hr />
      <br />
      <p>Buttons:</p>
      <br />
      <Button>View profile</Button>
      <ButtonGlobal />
    </WrapStyles>
  );
}
