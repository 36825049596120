import React from 'react';

const IconCalendar = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="100 100 800 800">
      <g>
        <path
          fill={fill}
          d="M746,430H254a4,4,0,0,0-4,4V734a16,16,0,0,0,16,16H734a16,16,0,0,0,16-16V434a4,4,0,0,0-4-4M350,706a4,4,0,0,1-4,4H294a4,4,0,0,1-4-4V654a4,4,0,0,1,4-4h52a4,4,0,0,1,4,4Zm0-90a4,4,0,0,1-4,4H294a4,4,0,0,1-4-4V564a4,4,0,0,1,4-4h52a4,4,0,0,1,4,4Zm90,90a4,4,0,0,1-4,4H384a4,4,0,0,1-4-4V654a4,4,0,0,1,4-4h52a4,4,0,0,1,4,4Zm0-90a4,4,0,0,1-4,4H384a4,4,0,0,1-4-4V564a4,4,0,0,1,4-4h52a4,4,0,0,1,4,4Zm0-90a4,4,0,0,1-4,4H384a4,4,0,0,1-4-4V474a4,4,0,0,1,4-4h52a4,4,0,0,1,4,4Zm90,180a4,4,0,0,1-4,4H474a4,4,0,0,1-4-4V654a4,4,0,0,1,4-4h52a4,4,0,0,1,4,4Zm0-90a4,4,0,0,1-4,4H474a4,4,0,0,1-4-4V564a4,4,0,0,1,4-4h52a4,4,0,0,1,4,4Zm0-90a4,4,0,0,1-4,4H474a4,4,0,0,1-4-4V474a4,4,0,0,1,4-4h52a4,4,0,0,1,4,4Zm90,180a4,4,0,0,1-4,4H564a4,4,0,0,1-4-4V654a4,4,0,0,1,4-4h52a4,4,0,0,1,4,4Zm0-90a4,4,0,0,1-4,4H564a4,4,0,0,1-4-4V564a4,4,0,0,1,4-4h52a4,4,0,0,1,4,4Zm0-90a4,4,0,0,1-4,4H564a4,4,0,0,1-4-4V474a4,4,0,0,1,4-4h52a4,4,0,0,1,4,4Zm90,90a4,4,0,0,1-4,4H654a4,4,0,0,1-4-4V564a4,4,0,0,1,4-4h52a4,4,0,0,1,4,4Zm0-90a4,4,0,0,1-4,4H654a4,4,0,0,1-4-4V474a4,4,0,0,1,4-4h52a4,4,0,0,1,4,4Z"
        />
        <path
          fill={fill}
          d="M734,300H655V270a20,20,0,0,0-40,0v30H385V270a20,20,0,0,0-40,0v30H266a16,16,0,0,0-16,16v80a4,4,0,0,0,4,4H746a4,4,0,0,0,4-4V316a16,16,0,0,0-16-16"
        />
      </g>
    </svg>
  );
};
export default IconCalendar;
