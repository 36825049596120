import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import ls from 'local-storage';
import styled from 'styled-components';
import DropdownSelect from 'components/DropdownSelect';
import SubHeader from 'components/SubHeader';
import Layout from 'components/Layout/layout';
import { P, H5 } from 'components/Text';
import { colors } from 'pages/variables';
import { Form, Input, Radio, Upload, message } from 'antd';
import ButtonAdd from 'components/Buttons/buttonAdd';
import isValidEmail from 'lib/isValidEmail';
import formAddField from 'lib/formAddField';
import normaliseString from '../../lib/normaliseString';
import isValidURL from '../../lib/isValidURL';

const { REACT_APP_CMS_URL } = process.env;

const formStyles = {
  background: '#ffffff',
  padding: '50px 0px',
  marginTop: '30px',
};

const buttonStyles = {
  float: 'right',
};

const StyledForm = styled(Form)`
  .radioBtns {
    .ant-form-item-control {
      flex: auto;
    }
  }
  .ant-select-selection-item {
    background: #e1e1e1 !important;
  }

  .ant-select-selector {
    border: solid 0.9px #939597 !important;
  }

  .ant-form-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 30px;
    label {
      font-family: KumbhSans-Bold;
      color: ${colors.text};
    }
  }
  input {
    background: ${colors.lightGrey};
    opacity: 0.6;
  }
  .uploadImg {
    height: 300px;
    padding-bottom: 30px;
    border-radius: 4.6px;
    border: solid 0.9px #d9d9d9;
    background-color: rgba(229, 231, 235, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 99%;
      height: 99%;
    }
    div {
      width: 200px !important;
      height: 200px !important;
      margin: 0;
      padding: 0;
    }
    .ant-form-item-control {
      display: flex;
      flex-direction: column;
      flex-grow: inherit;
    }
    .ant-upload {
      background-color: #e5e7eb;
      font-family: KumbhSans-Bold;
      color: ${colors.text};
    }
    .text-upload {
      background: gray;
      width: 140px;
      border-radius: 30px;
      color: white;
      font-size: 14px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid white;
      padding-top: 4px;
    }
  }
`;

const HRSlim = styled.hr`
  border: 0px;
  border-top: 1px solid #dcdcdd;
  margin: 40px;
`;

const WrapUploadtext = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UploadContent = () => {
  return (
    <WrapUploadtext>
      <p className="text-upload">Upload an image</p>
      <p className="text-drag">or drag and drop</p>
    </WrapUploadtext>
  );
};

const AddProfile = () => {
  const [selectedFiles, setSelectedFiles] = useState({ profile: {}, contact: {} });
  const [redirect, setRedirect] = useState(false);
  const [instanceFileList, setInstanceFileList] = useState({ profile: [], contact: [] });

  const profileProps = {
    multiple: false,
    onChange: ({ file, fileList }) => {
      if (file.status === 'removed') {
        setInstanceFileList((state) => ({ ...state, profile: [] }));
        setSelectedFiles((state) => ({ ...state, profile: {} }));
      }
      if (file.status === 'done') {
        message.success(`${file.name} file uploaded successfully`);
        setInstanceFileList((state) => ({ ...state, profile: fileList }));
        setSelectedFiles((state) => ({ ...state, profile: fileList[0].originFileObj }));
      } else if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
    },
  };

  const contactProps = {
    multiple: false,
    onChange: ({ file, fileList }) => {
      if (file.status === 'removed') {
        setInstanceFileList((state) => ({ ...state, contact: [] }));
        setSelectedFiles((state) => ({ ...state, contact: {} }));
      }
      if (file.status === 'done') {
        message.success(`${file.name} file uploaded successfully`);
        setInstanceFileList((state) => ({ ...state, contact: fileList }));
        setSelectedFiles((state) => ({ ...state, contact: fileList[0].originFileObj }));
      } else if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
    },
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const onFinish = (values) => {
    const loc = JSON.stringify(values.selectloc);
    const spec = JSON.stringify(values.selectspec);
    // const contact = JSON.stringify(values.selectcontact);
    const form = {
      status: 'draft',
      title: `${values.firstname} ${values.surname}`,
      fields: {
        language: values.selectlang,
        title: values.title,
        veeva_link: values.veevaLink,
        location: loc.match(/\d+/g),
        specialism: spec.match(/\d+/g),
        // contact_relationship: contact.match(/\d+/g),
        region: values.radioregion,
        availability_to_travel: values.radiotravel,
        contact_name: values.contact_name,
        contact_email: values.contact_email,
      },
    };
    const instance = axios.create({
      baseURL: `${REACT_APP_CMS_URL}/wp-json/jwt-auth/v1/token`,
      headers: { Authorization: `Bearer ${ls.get('session_token')}` },
    });
    const sessionUser = ls.get('session_user') && normaliseString(ls.get('session_user'));
    if (sessionUser && isValidEmail(sessionUser)) {
      formAddField(form, 'post_creator', sessionUser);
    }
    instance
      .post(`${REACT_APP_CMS_URL}/wp-json/wp/v2/profiles/`, form)
      .then((res) => {
        const post_id = res.data.id.toString();
        const photo = new FormData();
        photo.append('lxl_field_type', 'profile');
        photo.append('object_id', post_id);
        photo.append('file', selectedFiles.profile);
        axios.post(`${REACT_APP_CMS_URL}/wp-json/lxl/v1/file/upload`, photo);
        return post_id;
      })
      .then((post_id) => {
        const photo = new FormData();
        photo.append('lxl_field_type', 'contact');
        photo.append('object_id', post_id);
        photo.append('file', selectedFiles.contact);
        return axios.post(`${REACT_APP_CMS_URL}/wp-json/lxl/v1/file/upload`, photo);
      })
      .catch((err) => {
        // eslint-disable-next-line no-alert
        alert(err);
      });
    setRedirect(true);
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log('Failed:', errorInfo);
  };

  return (
    <Layout>
      {redirect && (
        <>
          <Redirect to="/profile-submitted" />
        </>
      )}
      <>
        <SubHeader breadcrumb={['KEE directory', 'add profile']} />
        <P>
          From this page you can add a new KEE to the directory. These will be reviewed and approved
          before appearing in the KEE directory. Once the KEE has been added, you and others will be
          able to search for and add them to future activities. Please complete the form and submit
          for review.
          <br />
          Profiles will typically be approved within 5 working days.
        </P>
        <StyledForm
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={formStyles}
        >
          <Form.Item
            labelAlign="left"
            label="First name"
            name="firstname"
            message=""
            rules={[{ required: true, message: 'First name is required' }]}
          >
            <Input placeholder="Type name here" />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Surname"
            name="surname"
            rules={[{ required: true, message: 'Surname is required' }]}
          >
            <Input placeholder="Type last name here" />
          </Form.Item>

          <Form.Item labelAlign="left" label="Title" name="title">
            <Input placeholder="Type title here" />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Add Veeva profile URL"
            name="veevaLink"
            rules={[
              {
                required: true,
                message: 'Veeva profile URL is required',
              },
              // @see https://ant.design/components/form/#components-form-demo-register
              // eslint-disable-next-line no-empty-pattern
              ({}) => ({
                validator(_, value) {
                  const errorMsg = {
                    isEmpty: 'Veeva link is required',
                    isNotUrl: 'Please enter a valid URL',
                  };
                  if (isValidURL(value)) {
                    return Promise.resolve();
                  }
                  if (value.length === 0) {
                    return Promise.reject(new Error(errorMsg.isEmpty));
                  }
                  return Promise.reject(new Error(errorMsg.isNotUrl));
                },
              }),
            ]}
          >
            <Input placeholder="Type veeva link here" />
          </Form.Item>

          <HRSlim />

          <DropdownSelect type="specialism" name="selectspec" message="Select a specialism" />

          <DropdownSelect type="location" name="selectloc" message="Select a location" />

          <HRSlim />

          <Form.Item
            labelAlign="left"
            label="Language"
            name="selectlang"
            rules={[{ required: true, message: 'Language is required' }]}
          >
            <Input placeholder="Type language here" />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            name="radiotravel"
            label="Availability to travel internationally"
            className="radioBtns"
          >
            <Radio.Group>
              <Radio value="Yes">Yes</Radio>
              <Radio value="No">No</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            labelAlign="left"
            name="radioregion"
            label="Region"
            className="radioBtns"
            rules={[{ required: true, message: 'Select a region' }]}
          >
            <Radio.Group>
              <Radio value="9">
                Global:
                <H5>
                  Is this KEE part of the list of KEEs involved in Astellas global team initiatives?
                </H5>
              </Radio>
              <Radio value="10">
                Local: <H5>Is this KEE involved in local affiliate initiatives only?</H5>
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item valuePropName="profile_picture" className="uploadImg">
            <label htmlFor="profile_picture" className="ant-form-item-required" title="Image">
              Profile image
            </label>
            <Upload
              {...profileProps}
              name="profile_picture"
              customRequest={dummyRequest}
              listType="picture-card"
              accept=".png, .jpg"
            >
              {instanceFileList.profile.length < 1 && <UploadContent />}
            </Upload>
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Contact name"
            name="contact_name"
            message=""
            rules={[{ required: true, message: 'Contact name is required' }]}
          >
            <Input placeholder="Type contact name here" />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Contact email"
            name="contact_email"
            message=""
            rules={[{ required: true, message: 'Contact email is required' }]}
          >
            <Input placeholder="Type contact email here" />
          </Form.Item>

          <Form.Item valuePropName="contact_picture" className="uploadImg">
            <label htmlFor="contact_picture" className="ant-form-item-required" title="Image">
              Contact picture
            </label>
            <Upload
              {...contactProps}
              name="contact_picture"
              customRequest={dummyRequest}
              listType="picture-card"
              accept=".png, .jpg"
            >
              {instanceFileList.contact.length < 1 && <UploadContent />}
            </Upload>
          </Form.Item>

          <Form.Item labelAlign="left">
            <ButtonAdd htmlType="submit" styles={buttonStyles}>
              Submit for review
            </ButtonAdd>
          </Form.Item>
        </StyledForm>
      </>
    </Layout>
  );
};

export default AddProfile;
