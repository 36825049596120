import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Input, Radio, DatePicker, Upload, message } from 'antd';
import axios from 'axios';
import ls from 'local-storage';
import styled from 'styled-components';
import DropdownSelect from 'components/DropdownSelect/local';
import DropdownSelectXHR from 'components/DropdownSelect';
import DropdownSelectEngagement from 'components/DropdownSelect/Engagement';
import SubHeader from 'components/SubHeader';
import Layout from 'components/Layout/layout';
import { P, H5 } from 'components/Text';
import { colors } from 'pages/variables';
import ButtonAdd from 'components/Buttons/buttonAdd';
import isValidEmail from 'lib/isValidEmail';
import formAddField from 'lib/formAddField';
import normaliseString from '../../lib/normaliseString';

const { TextArea } = Input;

const { REACT_APP_CMS_URL } = process.env;
const { RangePicker } = DatePicker;
const formStyles = {
  background: '#ffffff',
  padding: '50px 0px',
  marginTop: '30px',
};

const buttonStyles = {
  float: 'right',
};

const StyledForm = styled(Form)`
  .radioBtns {
    .ant-form-item-control {
      flex: auto;
    }
  }
  .ant-select-selection-item {
    color: #939597;
    // background: #e1e1e1 !important;
  }

  .ant-select-selector {
    border: solid 0.9px #939597 !important;
  }

  .ant-form-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 30px;
    label {
      font-family: KumbhSans-Bold;
      color: ${colors.text};
    }
    &.regularFont {
      label {
        font-family: KumbhSans-Regular;
      }
    }
  }
  input {
    background: ${colors.lightGrey};
    opacity: 0.6;
  }
  .uploadImg {
    height: 300px;
    padding-bottom: 30px;
    border-radius: 4.6px;
    border: solid 0.9px #d9d9d9;
    background-color: rgba(229, 231, 235, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 99%;
      height: 99%;
    }
    div {
      width: 200px !important;
      height: 200px !important;
      margin: 0;
      padding: 0;
    }
    .ant-form-item-control {
      display: flex;
      flex-direction: column;
      flex-grow: inherit;
    }
    .ant-upload {
      background-color: #e5e7eb;
      font-family: KumbhSans-Bold;
      color: ${colors.text};
    }
    .text-upload {
      background: gray;
      width: 140px;
      border-radius: 30px;
      color: white;
      font-size: 14px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid white;
      padding-top: 4px;
    }
  }
`;

const HRSlim = styled.hr`
  border: 0px;
  border-top: 1px solid #dcdcdd;
  margin: 40px;
`;

const TimeZoneOptions = [
  'GMT-11 - America/Nome',
  'GMT-10 - Pacific/Honolulu',
  'GMT-9 - America/Anchorage',
  'GMT-8 - America/Tijuana',
  'GMT-7 - America/Phoenix',
  'GMT-6 - America/Winnipeg',
  'GMT-5 - America/Vancouver',
  'GMT-4 - Atlantic/Cape_Verde',
  'GMT-3 - America/Argentina/Buenos_Aires',
  'GMT-2 - Atlantic/Azores',
  'GMT-1 - Africa/Algiers',
  'GMT - Europe/London',
  'GMT+1 - Europe/Rome',
  'GMT+2 - Europe/Kaliningrad',
  'GMT+3 - Europe/Moscow',
  'GMT+4 - Asia/Yerevan',
  'GMT+5 - Asia/Karachi',
  'GMT+6- Asia/Omsk',
  'GMT+7 - Asia/Krasnoyarsk',
  'GMT+8 - Asia/Shanghai',
  'GMT+9 - Asia/Tokyo',
  'GMT+10 - Australia/Sydney',
  'GMT+11 - Asia/Sakhalin',
  'GMT+12 - Pacific/Auckland',
];

const WrapUploadtext = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UploadContent = () => {
  return (
    <WrapUploadtext>
      <p className="text-upload">Upload an image</p>
      <p className="text-drag">or drag and drop</p>
    </WrapUploadtext>
  );
};

const AddEvent = () => {
  const [redirect, setRedirect] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [fileList, setFileList] = useState([]);

  const props = {
    name: 'file',
    multiple: false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // eslint-disable-next-line no-console
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'removed') {
        setFileList('');
        setSelectedFiles('');
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setFileList(info.fileList);
        setSelectedFiles(info.fileList[0].originFileObj);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const onFinish = (values) => {
    let startDate = '';
    let startTime = '';
    let endDate = '';
    let endTime = '';
    let selectprofilesValue = '';

    if (values.date) {
      const [start, end] = values.date;
      // eslint-disable-next-line
      const startd = new Date(start._d);
      // eslint-disable-next-line
      const endd = new Date(end._d);

      startDate = `${startd.getMonth() + 1}/${startd.getDate()}/${startd.getFullYear()}`;
      startTime = `${startd.getHours()}:${startd.getMinutes()}`;

      endDate = `${endd.getMonth() + 1}/${endd.getDate()}/${endd.getFullYear()}`;
      endTime = `${endd.getHours()}:${endd.getMinutes()}`;
    }

    if (values.selectprofiles) {
      selectprofilesValue = JSON.stringify(values.selectprofiles).match(/\d+/g);
    } else {
      selectprofilesValue = '';
    }

    const newCategory = {
      name: JSON.stringify(values.category)
        .replace('[', '')
        .replace(']', '')
        .replace('"', '')
        .replace('"', ''),
    };

    const str = JSON.stringify(values.category)
      .replace('[', '')
      .replace(']', '')
      .replace('"', '')
      .replace('"', '');
    const patt = new RegExp('-');
    const res = patt.test(str);

    if (res === true) {
      const valCat = JSON.stringify(values.category);
      const valCatMatch = valCat.match(/\d+/g);
      const instance = axios.create({
        baseURL: `${REACT_APP_CMS_URL}/wp-json/jwt-auth/v1/token`,
        headers: { Authorization: `Bearer ${ls.get('session_token')}` },
      });
      const sessionUser = ls.get('session_user') && normaliseString(ls.get('session_user'));
      const form = {
        status: 'draft',
        title: values.title,
        fields: {
          date: startDate,
          enddate: endDate,
          start_time: startTime,
          end_time: endTime,
          default_time_zone: values.defaulttimezone,
          description: values.description,
          location: JSON.stringify(values.location).match(/\d+/g),
          event_relationship: selectprofilesValue,
          time_zone: values.timezone,
          region: values.radioregion,
          category: JSON.stringify(valCatMatch).replace(/\D/g, ''),
          contact_name: values.contact_name,
          contact_email: values.contact_email,
        },
      };
      if (sessionUser && isValidEmail(sessionUser)) {
        formAddField(form, 'post_creator', sessionUser);
      }
      instance
        .post(`${REACT_APP_CMS_URL}/wp-json/wp/v2/events/`, form)
        .then((res2) => {
          const num = res2.data.id;
          const n = num.toString();
          const photo = new FormData();
          photo.append('lxl_field_type', 'contact');
          photo.append('object_id', n);
          photo.append('file', selectedFiles);
          return axios.post(`${REACT_APP_CMS_URL}/wp-json/lxl/v1/file/upload`, photo);
        })
        .then(() => {
          setRedirect(true);
        })
        .catch((err) => {
          // eslint-disable-next-line no-alert
          alert(err);
        });
    } else {
      const instance = axios.create({
        baseURL: `${REACT_APP_CMS_URL}/wp-json/jwt-auth/v1/token`,
        headers: { Authorization: `Bearer ${ls.get('session_token')}` },
      });
      const sessionUser = ls.get('session_user') && normaliseString(ls.get('session_user'));
      instance
        .post(`${REACT_APP_CMS_URL}/wp-json/wp/v2/category/`, newCategory)
        .then((res11) => {
          const form = {
            status: 'draft',
            title: values.title,
            fields: {
              date: startDate,
              enddate: endDate,
              start_time: startTime,
              end_time: endTime,
              default_time_zone: values.defaulttimezone,
              description: values.description,
              location: JSON.stringify(values.location).match(/\d+/g),
              event_relationship: selectprofilesValue,
              time_zone: values.timezone,
              region: values.radioregion,
              category: res11.data.id,
              contact_name: values.contact_name,
              contact_email: values.contact_email,
            },
          };
          if (isValidEmail(sessionUser)) {
            formAddField(form, 'post_creator', sessionUser);
          }
          instance
            .post(`${REACT_APP_CMS_URL}/wp-json/wp/v2/events/`, form)
            .then((res4) => {
              const num = res4.data.id;
              const n = num.toString();
              const photo = new FormData();
              photo.append('lxl_field_type', 'contact');
              photo.append('object_id', n);
              photo.append('file', selectedFiles);
              return axios.post(`${REACT_APP_CMS_URL}/wp-json/lxl/v1/file/upload`, photo);
            })
            .then(() => {
              setRedirect(true);
            })
            .catch((err) => {
              // eslint-disable-next-line no-alert
              alert(err);
            });
        })
        .then(() => {
          setRedirect(true);
        })
        .catch((err) => {
          // eslint-disable-next-line no-alert
          alert(err);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log('Failed:', errorInfo);
  };

  return (
    <Layout>
      {redirect && (
        <>
          <Redirect to="/event-submitted" />
        </>
      )}
      <>
        <SubHeader breadcrumb={['Event calendar', 'add event']} />
        <P>
          From this page you can add a new Event to the calendar. These will be reviewed and
          approved before appearing in the calendar. Once the event has been added, you and others
          will be able to search for and add them to future activities. Please complete the form and
          submit for review.
          <br />
          Events will typically be approved within 5 working days.
        </P>
        <StyledForm
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={formStyles}
        >
          <Form.Item
            labelAlign="left"
            label="Title (Event title must be less than 60 characters)"
            name="title"
            shouldUpdate={(prevValues, curValues) => prevValues.additional !== curValues.additional}
            rules={[
              {
                required: true,
              },
              // @see https://ant.design/components/form/#components-form-demo-register
              // eslint-disable-next-line no-empty-pattern
              ({}) => ({
                validator(_, value) {
                  const maxTitleLength = 60;
                  const errorMsg = {
                    isTooLong: 'Event title must be less than 60 characters',
                    isEmpty: 'Please enter a title',
                  };
                  if (value.length <= maxTitleLength) {
                    return Promise.resolve();
                  }
                  if (value.length === 0) {
                    return Promise.reject(new Error(errorMsg.isEmpty));
                  }
                  return Promise.reject(new Error(errorMsg.isTooLong));
                },
              }),
            ]}
          >
            <Input placeholder="Type title here" />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Description"
            name="description"
            message=""
            rules={[
              {
                required: true,
                message: 'Please add a description',
              },
            ]}
          >
            <TextArea placeholder="Type description here" />
          </Form.Item>

          <DropdownSelectEngagement
            type="category"
            name="category"
            label="Engagement type"
            message="Please select one engagement type"
            placeholder="Please select an engagement type"
          />

          <DropdownSelectXHR
            type="location"
            name="location"
            message="Please select a location"
            single
          />

          <HRSlim />

          <Form.Item labelAlign="left" label="Dates" name="date">
            <RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
          </Form.Item>

          <HRSlim />

          <DropdownSelect
            options={TimeZoneOptions}
            name="defaulttimezone"
            single
            label="Default time zone"
            message="Please select a default time zone"
            placeholder="Select default time zone"
          />

          <DropdownSelect
            options={TimeZoneOptions}
            name="timezone"
            required={false}
            label="Please select up to three time zones that will be displayed for your event"
            message="Please select a time zone"
            className="regularFont"
            placeholder="Select alternative time zones"
          />

          <HRSlim />

          <DropdownSelectXHR
            required={false}
            type="profiles"
            name="selectprofiles"
            label="KEEs involved"
            message="Please select a profile"
            placeholder="Select the KEEs involved"
          />

          <HRSlim />

          <Form.Item
            labelAlign="left"
            name="radioregion"
            label="Region"
            className="radioBtns"
            rules={[{ required: true, message: 'Select a region' }]}
          >
            <Radio.Group>
              <Radio value="9">
                Global:
                <H5>Global events are those organized and hosted by the Astellas global team</H5>
              </Radio>
              <Radio value="10">
                Local: <H5>Local events are those organized and hosted by local affiliate teams</H5>
              </Radio>
            </Radio.Group>
          </Form.Item>

          <HRSlim />

          <Form.Item
            labelAlign="left"
            label="Contact name"
            name="contact_name"
            message=""
            rules={[{ required: true, message: 'Contact name is required' }]}
          >
            <Input placeholder="Type contact name here" />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Contact email"
            name="contact_email"
            message=""
            rules={[{ required: true, message: 'Contact email is required' }]}
          >
            <Input placeholder="Type contact email here" />
          </Form.Item>

          <Form.Item valuePropName="file" className="uploadImg">
            <label htmlFor="basic_profileimage" className="ant-form-item-required" title="Image">
              Contact profile image
            </label>
            <Upload
              {...props}
              name="logo"
              customRequest={dummyRequest}
              listType="picture-card"
              accept=".png, .jpg"
            >
              {fileList.length < 1 && <UploadContent />}
            </Upload>
          </Form.Item>

          <HRSlim />

          <Form.Item labelAlign="left">
            <ButtonAdd htmlType="submit" styles={buttonStyles}>
              Submit for review
            </ButtonAdd>
          </Form.Item>
        </StyledForm>
      </>
    </Layout>
  );
};

export default AddEvent;
