import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Card as CardUI } from 'antd';
import { H4, H5, P } from 'components/Text';
import Button from 'components/Buttons';
import ButtonGlobal from 'components/Buttons/buttonGlobal';
import ButtonLocal from 'components/Buttons/buttonLocal';
import { colors, fonts } from 'pages/variables';
import Scheduled from 'pages/KeeDirectory/scheduledEvents';
import ProfPic from 'components/ProfilePic/profilePic';
import ProfilePic from '../../assets/img/profile.png';

const CardStyles = {
  display: 'flex',
  width: '260px',
  height: '360px',
  padding: 0,
  color: colors.text,
};

const CardBodyStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '100%',
};

const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;

  h4 {
    font-family: ${fonts.PlayFair};
  }

  &.footer-buttons {
    height: 10%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  }

  p {
    font-family: ${fonts.SpaceMono};
    margin-bottom: 10px;
  }
`;

export default function Card(props) {
  const history = useHistory();
  const { id, title, acf } = props;
  const {
    specialism,
    region,
    location,
    profile_picture,
    title: profession,
    event_relationship,
  } = acf;
  const isGlobal = region?.name === 'Global';
  return (
    <CardUI style={CardStyles} bodyStyle={CardBodyStyles}>
      <CardSection>
        <ProfPic img={profile_picture || ProfilePic} width="74" height="74" />
        <H4>{title.rendered}</H4>
        <P>{profession}</P>
        {specialism && (
          <H5>
            Specialism: <span>{specialism.map(({ name }) => name).join(', ')}</span>
          </H5>
        )}
        <H5>
          Regions: <span>{location.map(({ name }) => name).join(', ')}</span>
        </H5>
        <Scheduled profileId={id} eventRelationship={event_relationship} />
      </CardSection>
      <CardSection className="footer-buttons">
        <Button onClick={() => history.push(`/profile/${id}`, { from: id })}>View profile</Button>
        {isGlobal ? <ButtonGlobal /> : <ButtonLocal />}
      </CardSection>
    </CardUI>
  );
}
