import React from 'react';

const IconMundi = ({fill}) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="100 100 800 800"  >
        <g>
            <path fill={fill} d="M418,360h63a4,4,0,0,0,4-4V273.19a4,4,0,0,0-5.65-3.65c-19.89,8.71-39,29.67-54.32,60.39a268.07,268.07,0,0,0-10.75,24.64A4,4,0,0,0,418,360"/>
            <path fill={fill} d="M394.38,485H481a4,4,0,0,0,4-4V394a4,4,0,0,0-4-4H406.26a4,4,0,0,0-3.88,3,438.38,438.38,0,0,0-12,87.82,4,4,0,0,0,4,4.15"/>
            <path fill={fill} d="M406.26,610H481a4,4,0,0,0,4-4V519a4,4,0,0,0-4-4H394.38a4,4,0,0,0-4,4.15,438.38,438.38,0,0,0,12,87.82,4,4,0,0,0,3.88,3"/>
            <path fill={fill} d="M628.85,394.89a476.07,476.07,0,0,1,10.8,86.24,4,4,0,0,0,4,3.87H745.26a4,4,0,0,0,4-4.34,248.41,248.41,0,0,0-23.61-88.4A4,4,0,0,0,722,390H632.75a4,4,0,0,0-3.9,4.89"/>
            <path fill={fill} d="M601.8,316.51a308.37,308.37,0,0,1,16.68,40.74,4,4,0,0,0,3.8,2.75h77.19a4,4,0,0,0,3.26-6.32A250.48,250.48,0,0,0,572,260.52a4,4,0,0,0-4,6.61c12.47,12.86,23.88,29.42,33.86,49.38"/>
            <path fill={fill} d="M485,726.81V644a4,4,0,0,0-4-4H418a4,4,0,0,0-3.75,5.43A268.07,268.07,0,0,0,425,670.07c15.36,30.72,34.43,51.68,54.32,60.39a4,4,0,0,0,5.65-3.65"/>
            <path fill={fill} d="M632.75,610H722a4,4,0,0,0,3.61-2.26,248.62,248.62,0,0,0,23.61-88.4,4,4,0,0,0-4-4.34H643.64a4,4,0,0,0-4,3.87,476.07,476.07,0,0,1-10.8,86.24,4,4,0,0,0,3.9,4.89"/>
            <path fill={fill} d="M582,360a4,4,0,0,0,3.75-5.43A268.07,268.07,0,0,0,575,329.93c-15.36-30.72-34.43-51.68-54.32-60.39a4,4,0,0,0-5.65,3.65V356a4,4,0,0,0,4,4Z"/>
            <path fill={fill} d="M356.36,515H254.74a4,4,0,0,0-4,4.34,248.62,248.62,0,0,0,23.61,88.4A4,4,0,0,0,278,610h89.28a4,4,0,0,0,3.9-4.89,476,476,0,0,1-10.8-86.24,4,4,0,0,0-4-3.87"/>
            <path fill={fill} d="M398.2,683.49a309.17,309.17,0,0,1-16.68-40.74,4,4,0,0,0-3.8-2.75H300.53a4,4,0,0,0-3.26,6.32A250.48,250.48,0,0,0,428,739.48a4,4,0,0,0,4-6.61c-12.47-12.86-23.88-29.42-33.86-49.38"/>
            <path fill={fill} d="M274.36,392.26a248.4,248.4,0,0,0-23.6,88.4,4,4,0,0,0,4,4.34H356.36a4,4,0,0,0,4-3.87,476.07,476.07,0,0,1,10.8-86.24,4,4,0,0,0-3.9-4.89H278a4,4,0,0,0-3.61,2.26"/>
            <path fill={fill} d="M622.28,640a4,4,0,0,0-3.79,2.75,307.83,307.83,0,0,1-16.69,40.74c-10,20-21.39,36.52-33.86,49.38a4,4,0,0,0,4,6.61,250.48,250.48,0,0,0,130.75-93.16,4,4,0,0,0-3.26-6.32Z"/>
            <path fill={fill} d="M605.62,515H519a4,4,0,0,0-4,4v87a4,4,0,0,0,4,4h74.74a4,4,0,0,0,3.88-3,438.38,438.38,0,0,0,12-87.82,4,4,0,0,0-4-4.15"/>
            <path fill={fill} d="M582,640H519a4,4,0,0,0-4,4v82.81a4,4,0,0,0,5.65,3.65c19.89-8.71,39-29.67,54.32-60.39a268.07,268.07,0,0,0,10.75-24.64A4,4,0,0,0,582,640"/>
            <path fill={fill} d="M377.72,360a4,4,0,0,0,3.8-2.75,307.67,307.67,0,0,1,16.68-40.73c10-20,21.39-36.53,33.86-49.39a4,4,0,0,0-4-6.61,250.48,250.48,0,0,0-130.75,93.16,4,4,0,0,0,3.26,6.32Z"/>
            <path fill={fill} d="M593.74,390H519a4,4,0,0,0-4,4v87a4,4,0,0,0,4,4h86.62a4,4,0,0,0,4-4.15,438.38,438.38,0,0,0-12-87.82,4,4,0,0,0-3.88-3"/>
        </g>
      </svg>
  );
}
export default IconMundi;


