import * as React from 'react';
import styled from 'styled-components';

import Header from '../Header/header';

const Wrap = styled.div`
  padding-top: 22px;
  padding-left: 55px;
  padding-right: 55px;
  margin: 0;
`;

export default function Layout({ children }) {
  return (
    <Wrap>
      <Header />
      {children}
    </Wrap>
  );
}
