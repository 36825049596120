/**
 * Casts to string, trims & lower-cases supplied string,
 * eg. for comparing email addresses which may have inconsistent capitalisation.
 *
 * @param {string} str - string to normalise
 * @return {string}
 */
export default function normaliseString(str) {
  return String(str).trim().toLowerCase();
}
