import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useFetch from 'services/hooks';
import Loader from 'components/Loader';
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';
import { colors, sizes, fonts } from 'pages/variables';
import Text from 'components/Text';
import SubHeader from 'components/SubHeader';
import Layout from 'components/Layout/layout';
import Button from 'components/Buttons';
import ButtonAdd from 'components/Buttons/buttonAdd';
import Contact from 'components/Contact';
import ScheduledEvents from 'components/ScheduledEvents';
import ls from 'local-storage';
import ProfPic from 'components/ProfilePic/profilePic';
import Information from './Information';
import normaliseString from '../../lib/normaliseString';

const Edit = styled.div`
  margin-bottom: 20px;
  display flex;
  justify-content: end;
`;

const ProfileWrapper = styled.div`
  display: flex;
  background: #fff;
  padding: 20px;
  margin-bottom: 40px;
  overflow: hidden;
`;

const ColLeft = styled.div`
  width: 80%;
  padding-right: 20px;
`;

const ColRight = styled.div`
  width: 20%;
`;

const Section = styled.div`
  margin-bottom: 70px;
`;

const loaderStyle = {
  margin: '150px auto',
};

const Title = styled.div`
  font-family: ${fonts.PlayfairDisplayItalic};
  color: ${colors.primary};
  font-size: ${sizes.size35};
`;

const Profile = function Profile({ match }) {
  const [{ response, loading }] = useFetch(`profiles/${match.params.id}`);
  const history = useHistory();
  const [showEditBtn, setShowEditBtn] = useState(false);

  useEffect(() => {
    if (response?.acf) {
      const {
        acf: { post_creator },
      } = response;
      const sessionUser = ls.get('session_user') && normaliseString(ls.get('session_user'));
      if (post_creator && !/^\s*$/.test(post_creator)) {
        if (sessionUser === post_creator) {
          setShowEditBtn(true);
        }
      }
    }
  }, [response]);

  if (loading) {
    return (
      <Layout>
        <Loader style={loaderStyle} />
      </Layout>
    );
  }

  const { title, acf } = response;

  const {
    title: profession,
    specialism,
    profile_picture,
    region,
    location: locations,
    veeva_link,
    contact_name,
    contact_email,
    contact_picture,
    event_relationship,
  } = acf;

  const goTo = (url) => {
    window.open(url, '_blank');
  };

  const isGlobal = region?.name === 'Global';
  return (
    <Layout>
      <SubHeader breadcrumb={['KEE directory', 'profile']} Global={isGlobal} share />
      {showEditBtn ? (
        <Edit>
          <Button
            onClick={() =>
              history.push(`/edit-profile/${response.id}`, {
                from: `${response.id}`,
              })
            }
          >
            Edit
          </Button>
        </Edit>
      ) : null}
      <Section>
        <ProfileWrapper>
          <ColLeft>
            <Title>{title.rendered}</Title>
            <Text size={sizes.size16}>{profession}</Text>
            <br />
            {veeva_link && (
              <ButtonAdd onClick={() => goTo(veeva_link)}>View VEEVA profile</ButtonAdd>
            )}
          </ColLeft>
          <ColRight>
            <ProfPic img={profile_picture} width="150" height="150" />
          </ColRight>
        </ProfileWrapper>
      </Section>
      <Section>
        <hr />
        <Information
          profileId={match.params.id}
          locations={locations}
          specialism={specialism}
          global={isGlobal}
          eventRelationship={event_relationship}
        />
      </Section>

      {contact_name && (
        <Section>
          <hr />
          <Contact
            name={contact_name}
            email={contact_email}
            image={contact_picture}
            contactText={`Please contact a member of the team below if you would like to get in touch with ${title.rendered}.`}
          />
        </Section>
      )}

      <Section>
        <ScheduledEvents profileId={match.params.id} />
      </Section>
      <Section>
        <ScheduledEvents profileId={match.params.id} archived />
      </Section>
    </Layout>
  );
};

export default withRouter(Profile);
