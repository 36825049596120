import React from 'react';
import { useHistory } from 'react-router-dom';
import useFetch from 'services/hooks';
import styled from 'styled-components';

import { H2 } from 'components/Text';
import { colors } from 'pages/variables';
import { Card as CardUI } from 'antd';
import Loader from 'components/Loader';
import Button from 'components/Buttons';

const CardsWrapper = styled.div`
  margin-top: 20px;
  h2 {
    font-size: 25px;
    margin-top: 10px;
    margin-top: 10px;
  }
`;

const CardStyles = {
  display: 'flex',
  width: '260px',
  height: '360px',
  padding: 0,
  color: colors.text,
};

const CardBodyStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '100%',
};

const loaderStyle = {
  margin: '150px auto',
};

const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;

  &.footer-buttons {
    height: 10%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  }

  h2 {
    line-height: 1.1em;
    font-size: 25px;
  }
`;

const IconImg = styled.img`
  width: 75px;
`;

export default function RelatedInitiatives({ id }) {
  const [{ response, loading }] = useFetch(`initiatives/${id}`);
  const history = useHistory();

  if (loading) {
    return <Loader style={loaderStyle} />;
  }

  const post = response;

  return (
    <div>
      <CardsWrapper>
        <CardUI style={CardStyles} bodyStyle={CardBodyStyles}>
          <CardSection>
            <IconImg src={post.acf.icon.url} alt={post.acf.icon.alt} />
            <H2>{post.title.rendered}</H2>
          </CardSection>
          <Button onClick={() => history.push(`/global-initiatives/${id}`)}>Find out more</Button>
        </CardUI>
      </CardsWrapper>
    </div>
  );
}
