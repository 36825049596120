import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, NavLink, Switch } from 'react-router-dom';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import ls from 'local-storage';
import isValidEmail from '../../lib/isValidEmail';
import normaliseString from '../../lib/normaliseString';

import '../../styles/global.scss';
import GlobalStyle from './GlobalStyle';

import { login, authenticate } from '../../services/sso';

// pages
import EventCalendar from '../EventsCalendar';
import AddEvent from '../EventsCalendar/AddEvent';
import KeeDirectory from '../KeeDirectory';
import GlobalInitiatives from '../GlobalInitiatives';
import AboutUs from '../AboutUs';
import Profile from '../Profile';
import AddProfile from '../Profile/AddProfile';
import EditProfile from '../Profile/EditProfile';
import GlobalInitiativesProfilePage from '../InitiativeProfile';
import ThankYou from '../ThankYou';
import ThankYouEvent from '../ThankYou/event';
import Event from '../Event';
import Styleguide from '../Styleguide';
import EditEvent from '../Event/EditEvent';
import NotFound from '../error404';

import Text from '../../components/Text';
import Footer from '../../components/Footer';
import LoginWP from '../../components/LoginWP';
import { colors, fonts, sizes } from '../variables';
import ScrollToTop from '../../components/ScrollToTop';

// icons
import IconAt from '../../components/Icons/icon-at';
import IconCalendar from '../../components/Icons/icon-calendar';
import IconMundi from '../../components/Icons/icon-mundi';
import IconLogo from '../../components/Icons/icon-logo';
import IconDiagram from '../../components/Icons/icon-diagram';

function StyleguidePage() {
  return <Styleguide />;
}

function EventCalendarPage() {
  return <EventCalendar />;
}

function KeeDirectoryPage() {
  return <KeeDirectory />;
}

function GlobalInitiativesPage() {
  return <GlobalInitiatives />;
}

function AboutUsPage() {
  return <AboutUs />;
}

function ProfilePage() {
  return <Profile />;
}

function EventPage() {
  return <Event />;
}

function AddEventPage() {
  return <AddEvent />;
}

function EditEventPage() {
  return <EditEvent />;
}

function EditProfilePage() {
  return <EditProfile />;
}

const Nav = styled.ul`
  width: 100%;
  min-width: 200px;
  height: 100vh;
  position: fixed;
  background: ${colors.white};
  overflow: hidden;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    padding-left: 21px;
    :hover,
    &.active {
      background-color: #f6f6f7;
      .nav_link-icon {
        background-color: #d91e49;
      }
    }
    .nav_link-icon {
      width: 40px;
      height: 40px;
      background-color: #939597;
      margin-right: 10px;
    }
  }
`;

const MySite = () => {
  // The current width of the viewport
  const width = window.innerWidth;
  // The width below which the mobile view should be rendered
  const breakpoint = 1200;
  /* If the viewport is more narrow than the breakpoint render the
     mobile component, else render the desktop component */
  return width < breakpoint ? (
    <p>Please view this site in a bigger screen/resolution</p>
  ) : (
    <Row>
      <LoginWP />
      <Col span={5} className="Wrap-Content-Left">
        <Nav>
          <NavLink activeClassName="active" exact to="/">
            <div className="nav_link-icon">
              <IconCalendar fill="#fff" />
            </div>
            <li>
              <Text color={colors.text} size={sizes.size16} font={fonts.Kumbase}>
                Events calendar
              </Text>
            </li>
          </NavLink>
          <NavLink to="/kee-directory">
            <div className="nav_link-icon">
              <IconAt fill="#fff" />
            </div>
            <li>
              <Text color={colors.text} size={sizes.size16} font={fonts.Kumbase}>
                KEE directory
              </Text>
            </li>
          </NavLink>
          <NavLink to="/global-initiatives">
            <div className="nav_link-icon">
              <IconMundi fill="#fff" />
            </div>
            <li>
              <Text color={colors.text} size={sizes.size16} font={fonts.Kumbase}>
                Global initiatives
              </Text>
            </li>
          </NavLink>
          <NavLink to="/about-us">
            <div className="nav_link-icon">
              <IconLogo fill="#fff" />
            </div>
            <li>
              <Text color={colors.text} size={sizes.size16} font={fonts.Kumbase}>
                About us
              </Text>
            </li>
          </NavLink>
          <a href="https://veeva.link/search/?topic=Prostate%20Cancer" target="_new">
            <div className="nav_link-icon">
              <IconDiagram fill="#fff" />
            </div>
            <li>
              <Text color={colors.text} size={sizes.size16} font={fonts.Kumbase}>
                Oncolink
              </Text>
            </li>
          </a>
        </Nav>
        <Footer />
      </Col>
      <Col span={19} className="Wrap-Content-Right">
        <section className="wrapper">
          <Switch>
            <Route path="/" exact component={EventCalendarPage} />
            <Route path="/add-event" exact component={AddEventPage} />
            <Route path="/edit-event/:id" exact component={EditEventPage} />
            <Route path="/kee-directory" exact component={KeeDirectoryPage} />
            <Route path="/global-initiatives/" exact component={GlobalInitiativesPage} />
            <Route path="/global-initiatives/:id" exact component={GlobalInitiativesProfilePage} />
            <Route path="/about-us" exact component={AboutUsPage} />
            <Route path="/profile/:id" exact component={ProfilePage} />
            <Route path="/edit-profile/:id" exact component={EditProfilePage} />
            <Route path="/kee-directory/add-profile" exact component={AddProfile} />
            <Route path="/event/:id" exact component={EventPage} />
            <Route path="/event-submitted" exact component={ThankYouEvent} />
            <Route path="/profile-submitted" exact component={ThankYou} />
            <Route
              path="/global-initiatives-profile"
              exact
              component={GlobalInitiativesProfilePage}
            />
            <Route path="/styleguide" exact component={StyleguidePage} />
            <Route path="*" component={NotFound} />
          </Switch>
        </section>
      </Col>
    </Row>
  );
};

export default function AppRouter() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    authenticate().then((response) => {
      if (response.status === 200) {
        ls.clear();
        const { user } = response.data;
        const session_user = user && normaliseString(user.unique_name);
        console.log(`${session_user}`);
        if (isValidEmail(session_user)) {
          console.log(`isValidEmail: ${session_user}`);
          ls.set('session_user', session_user);
        }
        setAuthenticated(true);
      } else {
        login();
      }
    });
  }, []);

  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      {authenticated ? <MySite /> : <h1>Not Authorized</h1>}
    </Router>
  );
}
