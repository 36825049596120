import React from 'react';

const IconDiagram = ({fill}) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="100 100 800 800"  >
      <g>
      <path fill={fill} d="M373.72,639h-15a2,2,0,0,0-1.73,3l12.41,21.49a2,2,0,0,1-1.73,3H276a6,6,0,0,1-6-6V516a6,6,0,0,1,6-6H387a8,8,0,0,0,8-8v-4a8,8,0,0,0-8-8H276a26,26,0,0,0-26,26V660.5a26,26,0,0,0,26,26h91.67a2,2,0,0,1,1.73,3L357,711a2,2,0,0,0,1.73,3h15a8,8,0,0,0,6.93-4l17-29.51a8,8,0,0,0,0-8l-17-29.5a8,8,0,0,0-6.93-4"/>
        <path fill={fill} d="M724,313.5H613a8,8,0,0,0-8,8v4a8,8,0,0,0,8,8H724a6,6,0,0,1,6,6V484a6,6,0,0,1-6,6H632.34a2,2,0,0,1-1.73-3L643,465.51a2,2,0,0,0-1.73-3h-15a8,8,0,0,0-6.93,4l-17,29.5a8,8,0,0,0,0,8l17,29.51a8,8,0,0,0,6.93,4h15a2,2,0,0,0,1.73-3L630.6,513a2,2,0,0,1,1.73-3H724a26,26,0,0,0,26-26V339.5a26,26,0,0,0-26-26"/>
        <path fill={fill} d="M567,250H433a8,8,0,0,0-8,8V389a8,8,0,0,0,8,8H567a8,8,0,0,0,8-8V258a8,8,0,0,0-8-8M465.8,334.3a10.8,10.8,0,1,1,10.8-10.8,10.81,10.81,0,0,1-10.8,10.8m34.2,0a10.8,10.8,0,1,1,10.8-10.8A10.8,10.8,0,0,1,500,334.3m34.2,0A10.8,10.8,0,1,1,545,323.5a10.81,10.81,0,0,1-10.8,10.8"/>
        <path fill={fill} d="M567,426.5H433a8,8,0,0,0-8,8v131a8,8,0,0,0,8,8H567a8,8,0,0,0,8-8v-131a8,8,0,0,0-8-8M465.8,510.8A10.8,10.8,0,1,1,476.6,500a10.81,10.81,0,0,1-10.8,10.8m34.2,0A10.8,10.8,0,1,1,510.8,500,10.8,10.8,0,0,1,500,510.8m34.2,0A10.8,10.8,0,1,1,545,500a10.81,10.81,0,0,1-10.8,10.8"/>
        <path fill={fill} d="M567,603H433a8,8,0,0,0-8,8V742a8,8,0,0,0,8,8H567a8,8,0,0,0,8-8V611a8,8,0,0,0-8-8M465.8,687.3a10.8,10.8,0,1,1,10.8-10.8,10.81,10.81,0,0,1-10.8,10.8m34.2,0a10.8,10.8,0,1,1,10.8-10.8A10.8,10.8,0,0,1,500,687.3m34.2,0A10.8,10.8,0,1,1,545,676.5a10.81,10.81,0,0,1-10.8,10.8"/>
      </g>
      </svg>
  );
}
export default IconDiagram;
