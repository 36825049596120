/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Card as CardUI } from 'antd';
import styled from 'styled-components';
import { isFuture } from 'date-fns';

import Text, { H3 } from 'components/Text';
import { colors, fonts, sizes } from 'pages/variables';

import useFetch from 'services/hooks';

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 20px;
`;

const CardStyles = {
  display: 'flex',
  width: '260px',
  height: '200px',
  padding: 0,
  color: colors.text,
};

const CardBodyStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '100%',
};

const CardHeader = styled.div`
  margin-bottom: 10px;

  p {
    line-height: 25px;
  }
`;

export default function Information({
  profileId,
  global,
  locations,
  specialism = [],
  eventRelationship = [],
}) {
  const [{ response }] = useFetch(`events?lxl_per_page=100`);
  const [current, setCurrent] = useState(0);
  const specLen = specialism.length - 1;

  useEffect(() => {
    const profileIdInt = parseInt(profileId, 10);
    const eventsArr = response.filter(({ acf }) => {
      if (acf.date !== '') {
        const dateS = acf.date.split('/');
        const date = new Date(`${dateS[2]}-${dateS[1]}-${dateS[0]}`);
        const KeeIds = (acf.event_relationship || []).map((id) => {
          return id;
        });
        return KeeIds.includes(profileIdInt) && isFuture(date);
      }
    });
    setCurrent(eventsArr.length);
  }, [response, profileId]);

  return (
    <div>
      <H3>KEE information</H3>
      <CardsWrapper>
        <CardUI style={CardStyles} bodyStyle={CardBodyStyles}>
          <CardHeader>
            <Text color={colors.primary} size={sizes.size24} font={fonts.InterBlack}>
              Specialisms
            </Text>
          </CardHeader>
          {specialism.map(({ name }, i) => (
            <Text size={sizes.size16} font={fonts.KumBold} key={name}>
              {name}
              {i !== specLen && ','}
            </Text>
          ))}
        </CardUI>

        <CardUI style={CardStyles} bodyStyle={CardBodyStyles}>
          <CardHeader>
            <Text color={colors.primary} size={sizes.size24} font={fonts.InterBlack}>
              Regions
            </Text>
          </CardHeader>
          {global && (
            <Text size={sizes.size16} font={fonts.KumBold}>
              Global
            </Text>
          )}
          {locations.map(({ name }) => (
            <Text size={sizes.size16} font={fonts.KumBold} key={name}>
              {name}
            </Text>
          ))}
        </CardUI>

        <CardUI style={CardStyles} bodyStyle={CardBodyStyles}>
          <CardHeader>
            <Text color={colors.primary} size={sizes.size24} font={fonts.InterBlack}>
              Scheduled events
            </Text>
          </CardHeader>
          <Text size={sizes.size16} font={fonts.KumBold}>
            Current: {current}
          </Text>
          <Text size={sizes.size16} font={fonts.KumBold}>
            Total: {eventRelationship.length}
          </Text>
        </CardUI>
      </CardsWrapper>
    </div>
  );
}
