import React from 'react';
import styled from 'styled-components';
import Logo from '../Icons/logo';

const WrapHeader = styled.div`
  svg {
    width: 126px;
    height: 66px;
  }

  hr {
    margin-bottom: 30px;
  }
`;

const Header = () => (
  <WrapHeader>
    <Logo />
    <hr />
  </WrapHeader>
);

export default Header;
