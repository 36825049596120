import React from 'react';
import styled from 'styled-components';
import { Card as CardUI } from 'antd';
import { useHistory } from 'react-router-dom';
import Text, { H2, H3 } from 'components/Text';
import { decode } from 'html-entities';
import ButtonGlobal from 'components/Buttons/buttonGlobal';
import ButtonLocal from 'components/Buttons/buttonLocal';
import Button from 'components/Buttons';
import Categories from 'components/Categories';

import { colors, fonts, sizes } from 'pages/variables';

const CardStyles = {
  display: 'flex',
  width: '260px',
  height: '360px',
  padding: 0,
  color: colors.text,
};

const CardBodyStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '100%',
};

const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;

  &.footer-buttons {
    height: 10%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  }

  h2 {
    font-family: ${fonts.InterBlack};
    line-height: 1;
  }
`;

const Category = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  svg {
    width: 20px;
    border: 1px solid #dcdcdd;
    margin-right: 5px;
  }
`;

export default function Card({ id, title, global, hosts, category = [1] }) {
  const history = useHistory();
  const hasCategory = category[0] !== 1;
  const categoryObj = Categories[category[0]];
  return (
    <CardUI style={CardStyles} bodyStyle={CardBodyStyles}>
      <CardSection>
        <Category>
          {hasCategory && (
            <>
              {categoryObj && categoryObj.icon}
              {categoryObj && <H3 style={{ fontSize: 15, padding: 0 }}>{categoryObj.title}</H3>}
            </>
          )}
        </Category>
        <H2>{decode(`${title}`)}</H2>
        <Text color={colors.text} size={sizes.size18} font={fonts.PlayFair}>
          {hosts}
        </Text>
      </CardSection>
      <CardSection className="footer-buttons">
        <Button onClick={() => history.push(`/event/${id}`, { from: id })}>View Event</Button>
        {global ? <ButtonGlobal /> : <ButtonLocal />}
      </CardSection>
    </CardUI>
  );
}
