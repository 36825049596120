import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Card as CardUI } from 'antd';
import { H2 } from 'components/Text';
import Button from 'components/Buttons';
import { colors } from 'pages/variables';

const CardStyles = {
  display: 'flex',
  width: '260px',
  height: '360px',
  padding: 0,
  color: colors.text,
};

const CardBodyStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '100%',
};

const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;

  &.footer-buttons {
    height: 10%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  }
  h2 {
    line-height: 1.1em;
    font-size: 25px;
  }
`;

const IconImg = styled.img`
  width: 75px;
  padding-bottom: 20px;
`;

const Card = ({ Icon, Title, slug, onClick }) => {
  return (
    <CardUI style={CardStyles} bodyStyle={CardBodyStyles}>
      <CardSection id={slug}>
        <IconImg src={Icon} />
        <H2>{Title}</H2>
      </CardSection>
      <Button onClick={onClick}>Find out more</Button>
    </CardUI>
  );
};

export default withRouter(Card);
