import * as React from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'pages/variables';
import Home from '../../assets/img/home.svg';

const ButtonLocal = styled.div`
  background: ${colors.green};
  font-size: 14px !important;
  font-family: ${fonts.Kumbase};
  width: 80px;
  height: 32px;
  color: white;
  padding-left: 12px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
  span {
    margin-top: 7px;
    font-weight: normal;
    font-family: ${fonts.Kumbase}!important;
  }
`;

const ButtonGreen = ({ onClick }) => (
  <ButtonLocal onClick={onClick}>
    <img src={Home} alt="icon global" />
    <span>Local</span>
  </ButtonLocal>
);

export default ButtonGreen;
