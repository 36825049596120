import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { colors, fonts } from '../../pages/variables';

import Shortcut from '../../assets/img/shortcut.png';

const Footer = styled.div`
  // height: 170px;
  max-width: 20.83333333%;
  padding: 15px;
  position: fixed;
  bottom: 20px;
  border-top: 1px solid ${colors.text};
  font-size: 10px;

  a {
    height: auto;
    padding: 0;
    color: ${colors.primary};
    position: relative;
    padding-left: 15px;

    &:before {
      display: block;
      content: '';
      background: url(${Shortcut});
      background-size: 15px;
      width: 15px;
      height: 15px;
      position: absolute;
      left: -5px;
      top: -5px;
    }
  }
  b {
    font-family: ${fonts.KumBold};
  }

  span {
    font-size: 12px;
    display: block;
  }
`;

export default function index() {
  return (
    <Footer>
      <span>Privacy and compliance</span>
      <br />
      <Row>
        <Col span={24}>
          <a href="https://uploads-astellas-kee.lxlabs.co.uk/uploads/2020/12/Global_Xtandi_Portal_Guidance_APR20.pptx">
            Legal & compliance guidance
          </a>
        </Col>
        <Col span={24}>
          <a href="https://www.astellas.com/en/privacy-policy" target="_new">
            Privacy policy
          </a>
        </Col>
      </Row>
      <br />
      <b>Copyright &#9400; 2020 Astellas Pharma Ltd.</b>
      <br /> For internal use only. Not for display, distribution or promotion. This website is only
      intended for use as an Astellas business tool. It may not be used for non-work related social
      interaction.
    </Footer>
  );
}
