import React, { useState } from 'react';
import axios from 'axios';
import ls from 'local-storage';

const { REACT_APP_CMS_URL, REACT_APP_USERNAME, REACT_APP_PASSWORD } = process.env;
const baseURL = REACT_APP_CMS_URL;

const LoginWP = () => {
  const [loggedInStatus, setLoggedInStatus] = useState(false);
  useState(() => {
    // eslint-disable-next-line no-unused-vars
    const api = axios.create({
      baseURL,
      headers: { Authorization: `Bearer ${ls.get('session_token')}` },
    });

    const url = `${baseURL}/wp-json/jwt-auth/v1/token`;

    const headers = {
      'Content-Type': 'application/json',
    };

    const data = {
      username: REACT_APP_USERNAME,
      password: REACT_APP_PASSWORD,
    };

    axios
      .post(url, data, {
        headers,
      })
      .then((response) => {
        ls.set('session_token', response.data.token); // This is the WordPress JWT session token.
        setLoggedInStatus(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  });

  if (loggedInStatus !== false) {
    return (
      <>
        <div className="signed" />
      </>
    );
  }
  return (
    <>
      <div className="notsigned" />
    </>
  );
};

export default LoginWP;
