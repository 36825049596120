import React from 'react';
import IconGrad from 'components/Icons/icon-grad';
import IconStar from 'components/Icons/icon-star';
import IconWebminar from 'components/Icons/icon-webminar';

const categoryMap = {
  1: 'Uncategorized',
  12: { title: 'Webinar', icon: <IconWebminar fill="#939597" /> },
  13: { title: 'Preceptorship', icon: <IconGrad fill="#939597" /> },
  14: { title: 'Future KEE', icon: <IconStar fill="#939597" /> },
};

export default categoryMap;
