import React from 'react';
import styled from 'styled-components';
import { H1 } from 'components/Text';

const NotFound404 = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 100px;
`;

const NotFound = () => {
  return (
    <NotFound404>
      <H1>404- Page NotFound</H1>
    </NotFound404>
  );
};

export default NotFound;
