/**
 * One-time mutation of the supplied form object data to add a single, read-only field.
 *
 * @param {Object} obj - instance of form object data
 * @param {*} fieldName - name of form field to add
 * @param {*} value - value of field to add
 * @return object
 */
export default function formAddField(obj, fieldName, value) {
  Object.defineProperty(obj.fields, fieldName, {
    value,
    writable: false,
    enumerable: true,
  });
  Object.freeze(obj);
  return obj;
}
