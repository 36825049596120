import React from 'react';

const IconStar = ({ fill, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="100 100 800 800"
      preserveAspectRatio="xMinYMin meet"
      className={className}
    >
      <g>
        <path
          fill={fill}
          d="M587.74,511.09l47.51,146.23a4,4,0,0,1-6.15,4.48L504.7,571.42a8,8,0,0,0-9.4,0L370.9,661.8a4,4,0,0,1-6.15-4.48l47.51-146.23a8,8,0,0,0-2.91-8.95L285,411.76a4,4,0,0,1,2.35-7.23H441.07a8,8,0,0,0,7.61-5.53L496.2,252.76a4,4,0,0,1,7.6,0L551.32,399a8,8,0,0,0,7.61,5.53H712.69a4,4,0,0,1,2.35,7.23l-124.4,90.38a8,8,0,0,0-2.9,8.95m33.92,7.31L638,568.73a2,2,0,0,0,3.76.14l36.06-88.42a2,2,0,0,0-3-2.37l-52.42,38.08a2,2,0,0,0-.72,2.24M322.17,480.45l36.06,88.42a2,2,0,0,0,3.76-.14l16.35-50.33a2,2,0,0,0-.73-2.24L325.2,478.08a2,2,0,0,0-3,2.37M616.54,689.76l-95.19-69.17a4,4,0,0,0-6.35,3.24V750h90.47l12.62-56.13a4,4,0,0,0-1.55-4.11M485,623.83a4,4,0,0,0-6.35-3.24l-95.19,69.17a4,4,0,0,0-1.56,4.11L394.53,750H485Z"
        />
      </g>
    </svg>
  );
};
export default IconStar;
