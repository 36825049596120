import React from 'react';
import useFetch from 'services/hooks';
import styled from 'styled-components';

import { H2, H5 } from 'components/Text';
import { colors } from 'pages/variables';
import { Card as CardUI } from 'antd';
import Loader from 'components/Loader';
import Button from 'components/Buttons';
import IconAssets from '../../components/Icons/icon-assets';

const CardsWrapper = styled.div`
  h2 {
    font-size: 25px;
    margin-top: 10px;
    line-height: 1.1em;
  }
`;

const CardStyles = {
  display: 'flex',
  width: '260px',
  height: '360px',
  padding: 0,
  color: colors.text,
};

const CardBodyStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '100%',
};

const loaderStyle = {
  margin: '150px auto',
};

const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;

  &.footer-buttons {
    height: 10%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  }

  h2 {
    line-height: 1.1em;
    font-size: 25px;
  }
`;

export default function Assets({ id }) {
  const [{ response, loading }] = useFetch(`assets/${id}`);

  if (loading) {
    return <Loader style={loaderStyle} />;
  }

  const post = response;

  return (
    <div>
      <CardsWrapper>
        <CardUI style={CardStyles} bodyStyle={CardBodyStyles}>
          <CardSection>
            <IconAssets assetName={post.acf.type_file} />
            <H2>{post.title.rendered}</H2>
            <H5>{post.acf.asset_description}</H5>
          </CardSection>
          <Button>
            <a href={post.acf.asset_file} target="_blank" rel="noopener noreferrer" download>
              Download
            </a>
          </Button>
        </CardUI>
      </CardsWrapper>
    </div>
  );
}
