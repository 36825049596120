import React from 'react';

const IconList = ({ fill, className }) => {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 10"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M0,8.87h16.42V7.99H0V8.87z M0,4.88h16.42V3.99H0V4.88z M0,0v0.89h16.42V0H0z"
          />
        </g>
      </g>
    </svg>
  );
};
export default IconList;
