import React from 'react';

const IconAt = ({fill}) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="100 100 800 800"  >
      <g>
          <path fill={fill} d="M699,750h35a16,16,0,0,0,16-16V644a4,4,0,0,0-4-4H699a4,4,0,0,0-4,4V746a4,4,0,0,0,4,4" />
           <path fill={fill} d="M699,610h47a4,4,0,0,0,4-4V519a4,4,0,0,0-4-4H699a4,4,0,0,0-4,4v87a4,4,0,0,0,4,4" />
           <path fill={fill} d="M699,485h47a4,4,0,0,0,4-4V394a4,4,0,0,0-4-4H699a4,4,0,0,0-4,4v87a4,4,0,0,0,4,4" />
           <path fill={fill} d="M461.14,473c-15.4,0-25.76,14.28-25.76,32.2,0,13.72,5.32,23.24,17.64,23.24,13.72,0,24.36-14.84,24.64-35.84,0-11.76-5.88-19.6-16.52-19.6" />
           <path fill={fill} d="M734,250H266a16,16,0,0,0-16,16V734a16,16,0,0,0,16,16H661a4,4,0,0,0,4-4V368a8,8,0,0,1,8-8h73a4,4,0,0,0,4-4V266a16,16,0,0,0-16-16M504.82,555.3H503.7c-15.4,0-23.52-8.4-25.76-16.24-6.44,11.2-19.32,16.8-32.2,16.8-23.52,0-42.28-19.88-42.28-48.16,0-36.4,25.48-62.44,54.88-62.44,9.8,0,21.28,4.76,25.48,14.84l2.24-11.2H514.9l-14.56,68a13.63,13.63,0,0,0-.28,3.36,7.88,7.88,0,0,0,7.84,8.12c13.16,0,26.32-17.64,26.32-44.24,0-38.64-30-64.68-71.4-64.68-45.64,0-80.08,32.76-80.08,82,0,45.36,30.52,79.52,82,79.52,15.68,0,33.32-3.92,48.44-14.56l14.28,20.44c-14.56,12.88-41.72,21.28-62.72,21.28-65.52,0-113.12-42.28-113.12-105.84,0-65.52,50.12-110.6,110.88-110.6,58.24,0,100.8,39.48,100.8,92.12,0,42.84-29.68,70.84-58.52,71.4" />
      </g>
      </svg>
  );
}
export default IconAt;
