import React from 'react';
import styled from 'styled-components';
import { fonts } from 'pages/variables';

const Svg = styled.svg`
  font-size: 100px;
  width: 75px;
  height: 75px;
  text {
    font-family: ${fonts.KumBold};
  }
`;

const IconAssets = ({ assetName }) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
      <g>
        <rect fill="#a7a9ac" width="1000" height="1000" />
        <path
          fill="#fff"
          d="M575,259.66V398a2,2,0,0,0,2,2H715.34a4,4,0,0,0,2.83-6.83L581.83,256.83A4,4,0,0,0,575,259.66Z"
        />
        <path
          fill="#fff"
          d="M725,481V434a4,4,0,0,0-4-4H561a16,16,0,0,1-16-16V254a4,4,0,0,0-4-4H291a16,16,0,0,0-16,16V481a4,4,0,0,0,4,4H721A4,4,0,0,0,725,481Z"
        />
        <path
          fill="#fff"
          d="M275,699v35a16,16,0,0,0,16,16H709a16,16,0,0,0,16-16V699a4,4,0,0,0-4-4H279A4,4,0,0,0,275,699Z"
        />
        <rect fill="#fff" x="243.54" y="515" width="500" height="150" rx="16" />
        <text fill="#a7a9ac" transform="translate(396.06 627.32)">
          {assetName}
        </text>
      </g>
    </Svg>
  );
};
export default IconAssets;
