import React from 'react';

const IconLogo = ({ fill }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-110 -100 657.62 693.25"
    >
      <path
        fill={fill}
        d="M344.9,301.1V192.5a8,8,0,0,0-8-8H179.2a8,8,0,0,0-8,8V350.2a8,8,0,0,0,8,8H379.4a8.06,8.06,0,0,0,5.7-13.7l-37.9-37.9a7.88,7.88,0,0,1-2.3-5.5"
        transform="translate(-171.2 -143.37)"
      />
      <path
        fill={fill}
        d="M426.9,303.2,503.7,380a8,8,0,0,0,11.3,0L626.5,268.5a8,8,0,0,0,0-11.3h0L514.9,145.7a8,8,0,0,0-11.3,0L386.5,262.8,362,287.2a8,8,0,0,0,0,11.3,7.93,7.93,0,0,0,5.7,2.3h53.5a9.42,9.42,0,0,1,5.7,2.4"
        transform="translate(-171.2 -143.37)"
      />
      <path
        fill={fill}
        d="M344.9,478.9V587.4a8,8,0,0,1-8,8H179.2a8,8,0,0,1-8-8V429.8a8,8,0,0,1,8-8H379.4a8.06,8.06,0,0,1,5.7,13.7l-37.9,37.8a8.31,8.31,0,0,0-2.3,5.6"
        transform="translate(-171.2 -143.37)"
      />
      <path
        fill={fill}
        d="M426.9,476.8,503.7,400a8,8,0,0,1,11.3,0L626.5,511.5a8,8,0,0,1,0,11.3L514.9,634.3a8,8,0,0,1-11.3,0L386.5,517.2,362,492.8a8,8,0,0,1,0-11.3,7.87,7.87,0,0,1,5.6-2.3h53.5a8.91,8.91,0,0,0,5.8-2.4"
        transform="translate(-171.2 -143.37)"
      />
      <path
        fill={fill}
        d="M605.27,163.74v22.58h-6.9V163.74H589.9v-6h23.52v6Zm39.2,22.9V168.76l-7.53,17.88h-5l-7.21-17.57v17.57h-6.59V158.1h9.1l7.53,18.19,7.52-18.19h8.78v28.54Z"
        transform="translate(-171.2 -143.37)"
      />
    </svg>
  );
};
export default IconLogo;
