import React from 'react';
import { Button as ButtonUI } from 'antd';
import { colors } from 'pages/variables';

const stylesButton = {
  background: colors.primary,
  outline: 0,
  border: 0,
  borderRadius: '20px',
  lineHeight: 2,
};

const ButtonAdd = ({ children, onClick, styles, htmlType, icon }) => (
  <ButtonUI
    type="primary"
    icon={icon}
    onClick={onClick}
    style={{ ...stylesButton, ...styles }}
    htmlType={htmlType}
  >
    {children}
  </ButtonUI>
);

export default ButtonAdd;
