import React from 'react';
import { Form, Select } from 'antd';

const DropdownSelect = ({
  name,
  options,
  label,
  placeholder,
  single = false,
  required = true,
  message,
  className,
}) => {
  const { Option } = Select;

  const rules = { required, message: `${message}` };

  if (!single) {
    rules.type = 'array';
  }

  return (
    <Form.Item
      name={name}
      label={label || `Select ${name}`}
      rules={[rules]}
      labelAlign="left"
      className={className}
    >
      {!single ? (
        <Select mode="multiple" placeholder={placeholder || `Select ${name}`}>
          {options.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      ) : (
        <Select placeholder={placeholder || `Select ${name}`}>
          {options.map((option) => {
            return (
              <Option key={option.id ? option.id : option} value={option.id ? option.id : option}>
                {option.value ? option.value : option}
              </Option>
            );
          })}
        </Select>
      )}
    </Form.Item>
  );
};

export default DropdownSelect;
