import React, { useEffect, useState } from 'react';
// import styled from 'styled-components';
import { isFuture } from 'date-fns';

import { H5 } from 'components/Text';

import useFetch from 'services/hooks';

export default function Scheduled({ profileId, eventRelationship = [] }) {
  const [{ response }] = useFetch(`events?lxl_per_page=100`);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const profileIdInt = parseInt(profileId, 10);
    const eventsArr = response.filter(({ acf }) => {
      const dateS = acf.date.split('/');
      const date = new Date(`${dateS[2]}-${dateS[1]}-${dateS[0]}`);
      const KeeIds = (acf.event_relationship || []).map((id) => {
        return id;
      });
      return KeeIds.includes(profileIdInt) && isFuture(date);
    });
    setCurrent(eventsArr.length);
  }, [response, profileId]);

  return (
    <div>
      <H5>
        Scheduled events: <span>{current}</span>
      </H5>
      <H5>
        Total events: <span>{eventRelationship.length}</span>
      </H5>
    </div>
  );
}
