import * as React from 'react';
import styled from 'styled-components';
import { Button as ButtonUI } from 'antd';
import { colors } from 'pages/variables';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;

  hr {
    border: 0px;
    border-top: 1px solid #dcdcdd;
    margin: 0 !important;
    position: relative;
    top: 16px;
  }

  button {
    background: white;
    width: 150px;
    margin: 0 auto;
    font-size: 14px;
    border-radius: 0px;
    color: ${colors.text};
    border: 1px solid #dcdcdd;
    cursor: pointer;
    text-shadow: none;
    box-shadow: none;
    border-radius: 2px;

    :hover {
      cursor: pointer;
      border: 1px solid #dcdcdd;
      background: #dcdcdd;
    }
  }
`;

const ButtonLoad = ({ onClick }) => (
  <Wrapper>
    <hr />
    <ButtonUI type="primary" onClick={onClick}>
      Load more
    </ButtonUI>
  </Wrapper>
);

export default ButtonLoad;
