import React from 'react';
import { Select as SelectUI } from 'antd';

const { Option } = SelectUI;

const styles = {};

const Select = ({ placeholder, options, fullWidth, onChange }) => (
  <SelectUI
    onChange={onChange}
    placeholder={placeholder}
    style={{
      width: fullWidth ? '100%' : '80px',
      ...styles,
    }}
    defaultValue={options && options[0] && options[0].value}
  >
    {options.map(({ value, key }) => {
      return (
        <Option value={value} key={key}>
          {value}
        </Option>
      );
    })}
  </SelectUI>
);

export default Select;
