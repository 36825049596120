import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const config = {
  url: `${REACT_APP_API_URL}/wordpress`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const getData = (list) => {
  return axios
    .post(
      config.url,
      {
        endpoint: `wp/v2/${list.endpoint}`,
        query: list.query,
      },
      {
        headers: config.headers,
        withCredentials: true,
      }
    )
    .then((res) => {
      return res.data.wpData;
    })
    .catch((e) => {
      return e;
    });
};

export default getData;
