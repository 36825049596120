import React from 'react';

const IconGrad = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="100 100 800 800">
      <g>
        <g>
          <path
            fill={fill}
            d="M741.68,381.34,506.44,277.84a16,16,0,0,0-12.88,0L258.32,381.34a4,4,0,0,0,0,7.32l235.24,103.5a16,16,0,0,0,12.88,0l106-46.63a6.3,6.3,0,0,0-1-.58l-96.67-42.54A41.86,41.86,0,0,1,500,405c-16.57,0-30-8.95-30-20s13.43-20,30-20,30,8.95,30,20a14.49,14.49,0,0,1-.18,2.19l89.65,39.45a26,26,0,0,1,11.89,10.56l110.32-48.54a4,4,0,0,0,0-7.32"
          />
          <path
            fill={fill}
            d="M636.48,725h-23a8,8,0,0,1-8-8.51l4-63a8,8,0,0,1,8-7.49h15a8,8,0,0,1,8,7.49l4,63a8,8,0,0,1-8,8.51"
          />
          <path
            fill={fill}
            d="M615,477.18l-96.47,42.44a46,46,0,0,1-37.06,0L369.12,470.19A189.47,189.47,0,0,0,310.23,598.4a4,4,0,0,0,6.58,3.22,116.74,116.74,0,0,1,179.61,35.7,4,4,0,0,0,7.16,0A116.57,116.57,0,0,1,607.5,573.74c2.52,0,5,.09,7.5.25Z"
          />
          <path
            fill={fill}
            d="M635,474.24V577a115.21,115.21,0,0,1,17.91,5.9c9.26,3.92,22.56,13.64,30.46,19.74a4,4,0,0,0,6.44-3.34A189.37,189.37,0,0,0,635,474.24"
          />
        </g>
      </g>
    </svg>
  );
}
export default IconGrad;
