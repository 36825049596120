import React, { useState } from 'react';
import styled from 'styled-components';

import Layout from 'components/Layout/layout';
import useFetch from 'services/hooks';
import Loader from 'components/Loader';
import { colors, sizes, fonts } from 'pages/variables';
import Text, { H3, H5 } from 'components/Text';
import SubHeader from 'components/SubHeader';
import Contact from 'components/Contact';
import ButtonLoad from 'components/Buttons/buttonLoad';
import Assets from './Assets';
import RelatedInitiatives from './RelatedInitiatives';

const ProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 20px;
  margin-bottom: 40px;
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 260px);
  grid-gap: 20px;
  justify-content: space-between;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    display: block;
    > div {
      margin-right: 10px;
      margin-bottom: 10px;
      float: left;
    }
  }
  + div {
    clear: both;
  }
`;

const ProfileIcon = styled.img`
  width: 150px;
`;

const ColLeft = styled.div`
  padding-right: 20px;
`;

const Section = styled.div`
  margin-top: 50px;
  clear: both;
`;

const title = {
  lineHeight: '1em',
  marginBottom: '10px',
};

const loaderStyle = {
  margin: '150px auto',
};

const h5 = {
  paddingBottom: '10px',
};

const HRSlim = styled.hr`
  border: 0px;
  border-top: 1px solid #dcdcdd;
  margin-top: 20px !important;
`;

const InitiativeProfile = ({ match }) => {
  const LOAD_LEN = 3;
  const [visibleCards, setVisibleCards] = useState(LOAD_LEN);
  const [visibleCardsRelated, setVisibleCardsRelated] = useState(LOAD_LEN);
  const [{ response, loading }] = useFetch(`initiatives/${match.params.id}`);

  if (loading) {
    return (
      <Layout>
        <Loader style={loaderStyle} />
      </Layout>
    );
  }
  const post = response;

  const numberOfProfiles = post.acf.assets.length;
  const numberOfRelatedInitiatives = post.acf.initiatives_relationship.length;

  const handleClick = () => {
    if (visibleCards < numberOfProfiles) {
      const more = numberOfProfiles - visibleCards;
      setVisibleCards((prevVisibleCards) => prevVisibleCards + more);
    }
  };

  const showMoreRelatedInitiatives = () => {
    if (visibleCardsRelated < numberOfRelatedInitiatives) {
      const more = numberOfRelatedInitiatives - visibleCardsRelated;
      setVisibleCardsRelated((prevVisibleCardsRelated) => prevVisibleCardsRelated + more);
    }
  };

  return (
    <Layout>
      <SubHeader breadcrumb={['Global initiatives', post?.title?.rendered]} Global share />
      <Section key={post.id}>
        <ProfileWrapper>
          <ColLeft>
            <Text color={colors.primary} size={sizes.size35} font={fonts.InterBlack} style={title}>
              {post.title.rendered}
            </Text>
            <Text size={sizes.size16}>{post.acf.subtitle}</Text>
          </ColLeft>
          <div>
            <ProfileIcon src={post.acf.icon.url} alt={post.acf.icon.alt} />
          </div>
        </ProfileWrapper>
      </Section>

      {post.acf.contact_name && (
        <Section>
          <hr />
          <Contact
            name={post.acf.contact_name}
            email={post.acf.contact_email}
            contactText="Do you have any questions? Please get in touch!​"
          />
        </Section>
      )}

      <Section>
        <hr />
        <H3>Assets</H3>
        <Text size={sizes.size16}>
          You can download the available assets below to help run the initiatives in your own local
          market.
          <br />
          All assets created should go through the usual internal review process.
        </Text>
        <HRSlim />
        <H5 style={h5}>
          {post.acf.assets.length > 0 &&
            `Viewing ${
              numberOfProfiles <= LOAD_LEN ? numberOfProfiles : visibleCards
            } of ${numberOfProfiles} assets`}
        </H5>
        <CardsWrapper>
          {post.acf.assets.slice(0, visibleCards).map((acf) => (
            <div className="cards_inner" key={acf.ID}>
              <Assets key={acf.ID} id={acf.ID} />
            </div>
          ))}
        </CardsWrapper>
        {!(visibleCards >= numberOfProfiles) && <ButtonLoad onClick={handleClick} />}
      </Section>

      <Section>
        <hr />
        <H3>Related initiatives</H3>
        <HRSlim />
        <H5 style={h5}>
          {post.acf.initiatives_relationship.length > 0 &&
            `Viewing ${
              numberOfRelatedInitiatives <= LOAD_LEN
                ? numberOfRelatedInitiatives
                : visibleCardsRelated
            } of ${numberOfRelatedInitiatives} initiatives`}
        </H5>
        <CardsWrapper>
          {post.acf.initiatives_relationship.slice(0, visibleCardsRelated).map((acf) => (
            <div className="cards_inner" key={acf.ID}>
              <RelatedInitiatives key={acf.ID} id={acf.ID} />
            </div>
          ))}
        </CardsWrapper>
        {!(visibleCardsRelated >= numberOfRelatedInitiatives) && (
          <ButtonLoad onClick={showMoreRelatedInitiatives} />
        )}
      </Section>
    </Layout>
  );
};

export default InitiativeProfile;
