import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { ConfigProvider, Select, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import enGB from 'antd/lib/locale/en_GB';
import isSameDay from 'date-fns/isSameDay';
import isSameMonth from 'date-fns/isSameMonth';
import isWithinInterval from 'date-fns/isWithinInterval';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import toDate from 'date-fns/toDate';
import format from 'date-fns/format';
import { set } from 'date-fns';
import { decode } from 'html-entities';

import Loader from 'components/Loader';
import Layout from 'components/Layout/layout';
import { H1, H3, H5, P } from 'components/Text';
import Calendar from 'components/Calendar';
import Button from 'components/Buttons';
import ButtonGlobal from 'components/Buttons/buttonGlobal';
import ButtonLocal from 'components/Buttons/buttonLocal';
import ButtonAdd from 'components/Buttons/buttonAdd';
import IconCalendar from 'components/Icons/icon-calendar';
import IconList from 'components/Icons/icon-list';
import Capability from 'components/Capability';
import InnerPosts from 'components/InnerPosts';

import ScheduledEvents from 'components/ScheduledEvents';

import { colors } from 'pages/variables';
import useFetch from 'services/hooks';

import EventCal from './EventCalendarStyle';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const EventLi = styled.li`
  font-size: 12px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: ${(props) => (props.global ? colors.blue : colors.green)};
  color: ${colors.white};
  margin-bottom: 5px;
  padding: 2px 6px;

  &.multipleDay {
    position: absolute;
    left: 0px;
    width: 108%;
  }
`;

const Category = styled.div`
  display: flex;
  align-items: flex-start;

  svg {
    width: 20px;
    border: 1px solid #dcdcdd;
    margin-right: 5px;
  }
`;

const offset = (el) => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

const getDetails = ({
  id,
  title,
  global,
  description,
  hosts,
  category,
  location,
  date,
  enddate,
  start_time,
  end_time,
}) => {
  return {
    id,
    title,
    date,
    description,
    category,
    location,
    hosts,
    start_time,
    end_time,
    global,
    enddate,
    multipleDay: enddate && date !== enddate,
  };
};

const liStyle = {};
export default function EventCalendar() {
  const history = useHistory();
  const [{ response, loading }] = useFetch('events?lxl_per_page=100');
  const [events, setEvents] = useState([]);
  const [currMonth, setCurrMonth] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [position, setPosition] = useState({});
  const [modal, setModal] = useState({});
  const [toolTip, setToolTip] = useState(null);
  const ref = useRef({});

  useEffect(() => {
    const eventsArr = response.map((arr) => {
      const { id, acf, title } = arr;
      const dateS = acf.date.split('/');
      const obj = {
        id,
        title: title.rendered,
        global: acf.region.slug === 'global',
        ...acf,
        date: new Date(`${dateS[2]}-${dateS[1]}-${dateS[0]}`),
      };

      if (acf.enddate) {
        const dateE = acf.enddate.split('/');
        obj.enddate = new Date(`${dateE[2]}-${dateE[1]}-${dateE[0]}`);
      }

      return obj;
    });

    setEvents(eventsArr);
  }, [response]);

  function dateCellRender(value) {
    const disabled = currMonth !== getMonth(value);

    const listData = events
      .filter(({ date, enddate }) => {
        if (date && enddate) {
          const val = set(new Date(value), { hours: 0, minutes: 0, seconds: 0 });
          const start = set(new Date(date), { hours: 0, minutes: 0, seconds: 0 });
          const end = set(new Date(enddate), { hours: 23, minutes: 59, seconds: 0 });
          return (
            isSameDay(value, date) ||
            isWithinInterval(val, {
              start,
              end,
            })
          );
        }
        return isSameDay(value, date);
      })
      .map(getDetails);

    return (
      <ul className="events">
        {listData.reverse().map((item, i) => {
          const { multipleDay, id } = item;
          let { title } = item;

          const today = new Date(value);
          const startDay = new Date(item.date);

          if (today.getDate() !== startDay.getDate()) {
            title = ' ';
          }

          let style = {};
          const index = i === 0 ? 1 : i + 1;
          const top = `${index * 25}px`;

          if (liStyle[id]) {
            style = { top: liStyle[id] };
          } else {
            style = { top };
            liStyle[id] = top;
          }

          const disabledClass = disabled ? 'disabled' : '';

          return (
            <EventLi
              ref={(el) => {
                ref.current[item.id] = el;
              }}
              key={item.id}
              global={item.global}
              style={style}
              className={multipleDay ? `multipleDay ${disabledClass}` : ''}
              onClick={() => {
                setCurrMonth(getMonth(value));
                if (!disabled) {
                  const divOffset = offset(ref.current[item.id]);
                  setPosition({
                    left: divOffset.left,
                    top: divOffset.top,
                  });

                  setModal({ ...item });
                }
              }}
            >
              {decode(`${title}`)}
            </EventLi>
          );
        })}
      </ul>
    );
  }

  function monthCellRender(value) {
    const listData = events
      .filter(({ date }) => {
        return isSameMonth(value, date);
      })
      .map(getDetails);

    return (
      <ul className="events">
        {listData.map((item) => (
          <EventLi
            ref={(el) => {
              ref.current[item.id] = el;
            }}
            key={item.id}
            global={item.global}
            onClick={() => {
              const divOffset = offset(ref.current[item.id]);
              setPosition({
                left: divOffset.left,
                top: divOffset.top,
              });

              setModal({ ...item, year: true });
            }}
          >
            {decode(`${item.title}`)}
          </EventLi>
        ))}
      </ul>
    );
  }

  return (
    <Layout>
      <EventCal>
        <H1>Events calendar</H1>
        <P>
          Welcome to the events calendar. From this page you can explore scheduled, to be scheduled
          and archived global and local educational events involving KEEs. You can also add your own
          new events planned in your area.
        </P>
        <ButtonAdd
          icon={<PlusOutlined />}
          onClick={() => {
            history.push(`/add-event/`);
          }}
        >
          Add event
        </ButtonAdd>
        <hr />
        <ConfigProvider locale={enGB}>
          <Calendar
            dateCellRender={dateCellRender}
            monthCellRender={monthCellRender}
            headerRender={({ value, type, onChange, onTypeChange }) => {
              const yearNow = getYear(value);
              const yearMin = 2020;
              const yearMax = 2030;
              const month = months[getMonth(value)];
              const monthOptions = months.map((item) => {
                return (
                  <Select.Option className="month-item" key={item}>
                    {item}
                  </Select.Option>
                );
              });

              // Always activate current month in view:
              setCurrMonth(getMonth(value));

              const yearOptions = [];
              for (let i = yearMin; i <= yearMax; i += 1) {
                yearOptions.push(
                  <Select.Option className="year-item" key={i} value={i}>
                    {i}
                  </Select.Option>
                );
              }
              return (
                <div className="headerCal">
                  <div className="col col1">
                    <H3 className="h3">{`${month} ${yearNow}`}</H3>
                    {loading && <Loader style={{ marginLeft: '6px', marginTop: '4px' }} />}
                  </div>
                  <div className="col radio">
                    <Button
                      className={`toggle ${toggle ? 'active' : ''}`}
                      onClick={() => {
                        onTypeChange('year');
                        if (type === 'month') {
                          setToggle(!toggle);
                        }
                      }}
                    >
                      <IconList className="iconList" />
                    </Button>
                    <Button
                      className={`toggle ${toggle ? '' : 'active'}`}
                      onClick={() => {
                        onTypeChange('month');
                        if (type === 'year') {
                          setToggle(!toggle);
                        }
                      }}
                    >
                      <IconCalendar />
                    </Button>
                  </div>
                  <div className="col">
                    <Button
                      className="prev"
                      onClick={() => {
                        const index = months.indexOf(month);

                        const newDate = new Date(value.setMonth(index - 1));
                        setCurrMonth(getMonth(value));
                        onChange(toDate(newDate));
                      }}
                    >
                      &lt;
                    </Button>
                    <div className="mr-10">
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        value={String(month)}
                        className="selectMonth"
                        onChange={(selectedMonth) => {
                          const index = months.indexOf(selectedMonth);
                          const newDate = new Date(value.setMonth(index));
                          setCurrMonth(getMonth(value));
                          onChange(toDate(newDate));
                        }}
                      >
                        {monthOptions}
                      </Select>
                    </div>
                    <div>
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        className="selectYear"
                        value={String(yearNow)}
                        onChange={(newYear) => {
                          const newDate = new Date(value.setFullYear(newYear));
                          onChange(toDate(newDate));
                        }}
                      >
                        {yearOptions}
                      </Select>
                    </div>
                    <Button
                      className="next"
                      onClick={() => {
                        const index = months.indexOf(month);

                        const newDate = new Date(value.setMonth(index + 1));

                        setCurrMonth(getMonth(value));
                        onChange(toDate(newDate));
                      }}
                    >
                      &gt;
                    </Button>
                  </div>
                  <div>
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setToolTip(null);
                      }}
                    >
                      <div className="col localglobal">
                        <div className="mr-10">
                          <ButtonGlobal onClick={() => setToolTip('global')} />
                          {toolTip === 'global' && (
                            <Card className="localGlobalCard">
                              Global events are those organized and hosted by the Astellas global
                              team
                            </Card>
                          )}
                        </div>
                        <ButtonLocal onClick={() => setToolTip('local')} />
                        {toolTip === 'local' && (
                          <Card className="localGlobalCard local">
                            Local events are those organized and hosted by local affiliate teams
                          </Card>
                        )}
                      </div>
                    </OutsideClickHandler>
                  </div>
                </div>
              );
            }}
          />
        </ConfigProvider>
        {Object.keys(modal).length > 0 && (
          <OutsideClickHandler
            onOutsideClick={(e) => {
              if (e.target.nodeName === 'LI') {
                return;
              }
              setModal({});
            }}
          >
            <Card
              className="card"
              style={{ left: position.left - (modal.year ? 110 : 260), top: position.top - 233 }}
            >
              <div className="col">
                <Category>
                  <Capability capabilityId={modal.category} />
                </Category>
                <H3 className="h3">{decode(`${modal.title}`)}</H3>
                <H5 className="host">{modal.hosts}</H5>
                <H5 className="date">{format(new Date(modal.date), 'dd.MM.yyyy')}</H5>
                <H5 className="date">
                  {modal.start_time}-{modal.end_time}
                </H5>
                <InnerPosts inner="location" innerId={modal.location} />
              </div>
              <div className="col bottom">
                <Button onClick={() => history.push(`/event/${modal.id}`, { from: modal.id })}>
                  View Event
                </Button>
                {modal.global ? <ButtonGlobal /> : <ButtonLocal />}
              </div>
            </Card>
          </OutsideClickHandler>
        )}
      </EventCal>

      <ScheduledEvents />
    </Layout>
  );
}
