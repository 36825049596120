import React from 'react';
import styled from 'styled-components';
import { H5 } from 'components/Text';
import ButtonLocal from 'components/Buttons/buttonLocal';
import ButtonGlobal from 'components/Buttons/buttonGlobal';

const SubHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const BreadCrumb = styled.div`
  p {
    display: inline;
  }
`;

const Share = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-items: center;

  h5 {
    padding-right: 20px;
  }
`;

const h5 = {
  display: 'inline',
};

const shareLink = () => {
  const subject = 'Link Share';
  const body = window.location.href;
  window.location = `mailto:?subject=${subject}&body=${body}`;
};

export default function SubHeader({ breadcrumb, Global = false, share }) {
  const [prefix, postfix] = breadcrumb;
  const isGlobal = JSON.parse(Global);
  return (
    <SubHeaderWrapper>
      <BreadCrumb>
        <H5 style={h5}>{prefix} / </H5>
        <H5 style={h5}>
          <span>{postfix}</span>
        </H5>
      </BreadCrumb>
      {share && (
        <Share>
          <button type="button" onClick={shareLink} style={{ outline: 'none' }}>
            <H5 style={h5}>Share via email</H5>
          </button>
          {isGlobal ? <ButtonGlobal /> : <ButtonLocal />}
        </Share>
      )}
    </SubHeaderWrapper>
  );
}
