import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isFuture, isPast } from 'date-fns';

import { H3, H5 } from 'components/Text';
import CardEvent from 'components/CardEvent';
import Loader from 'components/Loader';
import ButtonLoad from 'components/Buttons/buttonLoad';

import useFetch from 'services/hooks';

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 260px);
  grid-gap: 20px;
  justify-content: space-between;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    display: flex;
    flex-wrap: wrap;
    div {
      margin-bottom: 10px;
    }

    }
  }
`;

const ScheduledEvents = styled.div`
  margin: 50px 0;

  h5 {
    padding-bottom: 10px;
  }
`;

const loaderStyle = {
  margin: '30px auto',
};

export default function ScheduledEventsFn({ profileId, archived = false }) {
  const LOAD_LEN = 3;
  const [events, setEvents] = useState([]);
  const [visibleCards, setVisibleCards] = useState(0);
  const [{ response, loading }] = useFetch(`events?lxl_per_page=100`);

  const handleClick = () => {
    const numberOfEvents = events.length;
    if (visibleCards < numberOfEvents) {
      const more = numberOfEvents - visibleCards;
      setVisibleCards((prevVisibleCards) => prevVisibleCards + more);
    }
  };

  useEffect(() => {
    if (profileId) {
      const profileIdInt = parseInt(profileId, 10);
      const eventsArr = response
        .filter(({ acf }) => {
          const dateS = acf.date.split('/');
          const date = new Date(`${dateS[2]}-${dateS[1]}-${dateS[0]}`);
          const KeeIds = (acf.event_relationship || []).map((id) => id);
          return KeeIds.includes(profileIdInt) && (archived ? isPast(date) : isFuture(date));
        })
        .map(({ acf, title, id }) => {
          return {
            id,
            title: title.rendered,
            global: acf.region.slug === 'global',
            ...acf,
          };
        });

      setEvents(eventsArr);
    } else {
      const eventsArr = response
        .filter(({ acf }) => {
          return !acf.date;
        })
        .map(({ acf, title, id }) => {
          return {
            id,
            title: title.rendered,
            global: acf.region.slug === 'global',
            ...acf,
          };
        });

      setEvents(eventsArr);
    }
  }, [response, profileId, archived]);

  useEffect(() => {
    const numberOfEvents = events.length;
    const initialLen = numberOfEvents <= LOAD_LEN ? numberOfEvents : LOAD_LEN;
    setVisibleCards(initialLen);
  }, [events]);

  const showViewing = !loading && events.length > 0;

  return (
    events.length > 0 && (
      <ScheduledEvents>
        <hr />
        {!profileId && <H3>To be scheduled (date TBC)</H3>}
        {profileId && !archived && <H3>Scheduled events</H3>}
        {profileId && archived && <H3>Archived events</H3>}
        <H5>
          {showViewing &&
            `Viewing ${visibleCards} of ${events.length} ${
              archived ? 'archived' : 'to be scheduled'
            } events`}
        </H5>
        <CardsWrapper>
          {loading && <Loader style={loaderStyle} />}
          {events.slice(0, visibleCards).map(({ id, title, global, hosts, category }) => {
            return (
              <CardEvent
                key={id}
                id={id}
                title={title}
                global={global}
                hosts={hosts}
                category={category}
              />
            );
          })}
        </CardsWrapper>
        {!(visibleCards >= events.length) && <ButtonLoad onClick={handleClick} />}
      </ScheduledEvents>
    )
  );
}
