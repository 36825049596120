import React from 'react';

const Logo = ({fill}) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 100 600 100"  >
        <g>
          <path fill="#939596" d="M533.47,507.08l-5.75,6.39a.81.81,0,0,0-.21.56v14.68a.83.83,0,0,1-.83.83h-11.5a.84.84,0,0,1-.84-.83V471.29a.84.84,0,0,1,.84-.83h11.5a.83.83,0,0,1,.83.83v21.77a.83.83,0,0,0,1.46.55l19.87-22.86a.82.82,0,0,1,.63-.29h15.08a.83.83,0,0,1,.62,1.39l-21.31,23.7a.84.84,0,0,0-.06,1l21.81,31.65a.84.84,0,0,1-.69,1.31H550.53a.87.87,0,0,1-.69-.36l-15.07-22a.82.82,0,0,0-1.3-.09" transform="translate(-225.66 -357.74)"/>
          <path fill="#939596" d="M608.78,529.15l-22.55-36a.83.83,0,0,0-1.54.44v35.1a.83.83,0,0,1-.83.83H572.44a.83.83,0,0,1-.83-.83V471.29a.83.83,0,0,1,.83-.83h14.7a.83.83,0,0,1,.71.4l20.38,33a.83.83,0,0,0,1.54-.44V471.29a.83.83,0,0,1,.84-.83H622.1a.84.84,0,0,1,.84.83v57.42a.84.84,0,0,1-.84.83H609.48a.84.84,0,0,1-.7-.39" transform="translate(-225.66 -357.74)"/>
          <path fill="#939596" d="M629,500a31.84,31.84,0,0,1,2.46-12.71,29.61,29.61,0,0,1,6.67-9.75,29.1,29.1,0,0,1,9.79-6.2,32.27,32.27,0,0,1,11.83-2.17,31.94,31.94,0,0,1,11.79,2.17A29.09,29.09,0,0,1,688,487.33,31.84,31.84,0,0,1,690.44,500,31.5,31.5,0,0,1,688,512.71a29.64,29.64,0,0,1-6.67,9.71,29.33,29.33,0,0,1-9.75,6.2,31.94,31.94,0,0,1-11.79,2.17,32.27,32.27,0,0,1-11.83-2.17,29.1,29.1,0,0,1-9.79-6.2,29.64,29.64,0,0,1-6.67-9.71A31.5,31.5,0,0,1,629,500m13.67-.08a19.39,19.39,0,0,0,1.5,8,16.32,16.32,0,0,0,4,5.54,15.71,15.71,0,0,0,5.5,3.25,18.53,18.53,0,0,0,12.24,0,15.37,15.37,0,0,0,5.46-3.25,16.65,16.65,0,0,0,3.92-5.54,19.39,19.39,0,0,0,1.5-8,19.76,19.76,0,0,0-1.5-8,16.08,16.08,0,0,0-3.92-5.59,16.47,16.47,0,0,0-5.46-3.25,17.88,17.88,0,0,0-12.24,0,16.85,16.85,0,0,0-5.5,3.25,15.78,15.78,0,0,0-4,5.59,19.76,19.76,0,0,0-1.5,8" transform="translate(-225.66 -357.74)"/>
          <path fill="#939596" d="M761.75,470.46h11.76a.83.83,0,0,1,.8,1.05l-15.63,57.42a.83.83,0,0,1-.8.61H745.54a.83.83,0,0,1-.79-.58l-11.61-36.12a.84.84,0,0,0-1.59,0L719.87,529a.83.83,0,0,1-.79.58H706.49a.84.84,0,0,1-.81-.61l-15.63-57.42a.84.84,0,0,1,.81-1.05h12.26a.85.85,0,0,1,.81.62l8.87,34.43a.83.83,0,0,0,1.6.05L725.58,471a.83.83,0,0,1,.79-.58h12.29a.83.83,0,0,1,.79.58l11.18,34.58a.83.83,0,0,0,1.6-.05l8.71-34.48a.83.83,0,0,1,.81-.63" transform="translate(-225.66 -357.74)"/>
          <path fill="#d81f4a" d="M325.66,449.05V385.71a4,4,0,0,0-4-4h-92a4,4,0,0,0-4,4v92a4,4,0,0,0,4,4H347a4,4,0,0,0,2.83-6.83l-23-23a4,4,0,0,1-1.17-2.83" transform="translate(-225.66 -357.74)"/>
          <path fill="#d81f4a" d="M372.67,449.88l44.79,44.79a4,4,0,0,0,5.66,0l65.05-65.05a4,4,0,0,0,0-5.66l-65.05-65a4,4,0,0,0-5.66,0l-67.88,67.88-15.09,15.09a4,4,0,0,0,2.82,6.83h32.53a4,4,0,0,1,2.83,1.17" transform="translate(-225.66 -357.74)"/>
          <path fill="#d81f4a" d="M325.66,551v63.34a4,4,0,0,1-4,4h-92a4,4,0,0,1-4-4v-92a4,4,0,0,1,4-4H347a4,4,0,0,1,2.83,6.83l-23,23a4,4,0,0,0-1.17,2.83" transform="translate(-225.66 -357.74)"/>
          <path fill="#d81f4a" d="M372.67,550.12l44.79-44.79a4,4,0,0,1,5.66,0l65.05,65.05a4,4,0,0,1,0,5.66l-65.05,65.05a4,4,0,0,1-5.66,0l-67.88-67.88-15.09-15.09a4,4,0,0,1,2.82-6.83h32.53a4,4,0,0,0,2.83-1.17" transform="translate(-225.66 -357.74)"/>
          <path fill="#d81f4a" d="M474.77,383.55v7.2h-2.15v-7.2h-2.68v-1.92h7.5v1.92Zm12.52,7.2v-5.67l-2.37,5.67h-1.6l-2.33-5.6v5.6h-2.1v-9.12h2.88l2.42,5.8,2.38-5.8h2.77v9.12Z" transform="translate(-225.66 -357.74)"/>
        </g>
      </svg>
  );
}
export default Logo;


