import { createGlobalStyle } from 'styled-components';
import { colors, sizes, fonts } from '../variables';

// // Global styles and resets
const GlobalStyle = createGlobalStyle`

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    font-family: ${fonts.Kumbase};
  }

  html {
    box-sizing: border-box;
    font-size: ${sizes.size20};
    font-family: ${fonts.Kumbase};
  }

  body {
    padding: 0;
    margin: 0;
    color: ${colors.text};
    background: ${colors.lightGrey};
  }

  .app {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    position: relative;
  }

  hr {
    border: none;
    border-bottom: 2px solid;
    border-color: #cccdcf;
    margin-bottom: 30px;
  }

  .btn {
    background: ${colors.primary};
    border: none;
    color: #fff;
    font-size: 14px;
    height: 30px;
    padding: 0 13px;
    &--default {
    }
    &--primary {
      border-radius: 40px;
      :hover {
        background-color: ${colors.text};
        color:#fff;
        border: none;
      }
    }
    :hover {
      background-color: ${colors.text};
      colo;r:#fff;
    }
  }

  .Wrap-Content-Right {
    background: ${colors.lightGrey};
    height: 100%;
    min-height: 100vh;

    section.wrapper {
      max-width: 930px;
      padding-bottom: 50px;
    }
  }

  .Wrap-Content-Left {
    background: ${colors.white};
    height: 100vh;
  }

`;
export default GlobalStyle;
