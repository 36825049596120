import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import useFetch from 'services/hooks';
import { withRouter } from 'react-router';
import { Col, Row } from 'antd';
import { isFuture, format, differenceInDays, differenceInHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { decode } from 'html-entities';
import Capability from 'components/Capability';
import Layout from 'components/Layout/layout';
import SubHeader from 'components/SubHeader';
import Loader from 'components/Loader';
import Contact from 'components/Contact';
import { colors, fonts } from 'pages/variables';
import { H1, H3 } from 'components/Text';
import Button from 'components/Buttons';
import ButtonLocal from 'components/Buttons/buttonLocal';
import ButtonGlobal from 'components/Buttons/buttonGlobal';
import Comments from 'components/Comments';
import InnerPosts from 'components/InnerPosts';
import PrintComments from 'components/Comments/PrintComments';
import ls from 'local-storage';
import ProfPic from 'components/ProfilePic/profilePic';
import normaliseString from '../../lib/normaliseString';

const Edit = styled.div`
  margin-bottom: 20px;
  display flex;
  justify-content: end;
`;

const MastHead = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  margin-bottom: 40px;
  overflow: hidden;
  width: 100%;

  .times,
  .date {
    display: block;
    font-family: ${fonts.SpaceMono};
  }

  .dates {
    min-height: 88px;
  }

  hr {
    margin-bottom: 80px;
  }

  h1 {
    max-width: 70%;
    line-height: 1.1;
    z-index: 1;
  }

  .ant-col-12 {
    &:first-child {
      align-self: flex-end;
    }

    &:last-child {
      text-align: right;
    }
  }

  .dateWrapper {
    padding: 17px 0 0 20px;
  }
`;

const TabSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: 20px;
  overflow: hidden;
  width: 100%;

  h3 {
    line-height: 1.1;
    color: ${colors.primary};
    font-family: ${fonts.InterBlack};
  }

  span {
    display: block;
    font-family: ${fonts.InterBlack};
  }

  .ant-row {
    padding-bottom: 15px;
  }
`;

const Header = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  top: 0px;
  left: 0px;
  padding: 10px;
`;

const Images = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 80px 10px 0 0px;

  img {
    max-width: auto;
    max-width: 190px;
    height: 190px;
    // filter: grayscale(100%);
    object-fit: cover;
  }
`;

const Speakers = styled.div`
  span {
    color: ${colors.primary};
    font-family: ${fonts.PlayfairDisplayItalic};
    font-size: 22px;
    line-height: 1;
  }
`;

const Desc = styled.div`
  border-top: 1px solid ${colors.primary};
  margin-top: 10px;
  padding: 20px;
`;

const Category = styled.div`
  svg {
    width: 30px;
    border: 1px solid #dcdcdd;
    margin-right: 5px;
  }
`;

const loaderStyle = {
  margin: '150px auto',
};
const Section = styled.div`
  margin-bottom: 70px;
`;

const TIMEF = 'HH.mm';
const MAX_ITEMS = 2;

const Event = function Event({ match }) {
  const history = useHistory();
  const [imageObj, setImageObj] = useState({});
  const [times, setTimes] = useState([]);
  const [duration, setDuration] = useState('');
  const [{ response, loading }] = useFetch(`events/${match.params.id}`);
  const [{ response: profileRes, loading: profileLoad }] = useFetch('profiles?lxl_per_page=100');
  const [showEditBtn, setShowEditBtn] = useState(false);

  useEffect(() => {
    if (response?.acf) {
      const {
        acf: { post_creator },
      } = response;
      const sessionUser = ls.get('session_user') && normaliseString(ls.get('session_user'));
      if (post_creator && !/^\s*$/.test(post_creator)) {
        if (sessionUser === post_creator) {
          setShowEditBtn(true);
        }
      }
    }
  }, [response]);

  useEffect(() => {
    const images = profileRes.map(({ id, acf }, i, arr) => {
      return {
        id,
        image: acf.profile_picture,
        name: arr[i]?.title?.rendered || '',
      };
    });

    const imagesObj = images.reduce((acc, post) => {
      const { id, image, name } = post;
      return { ...acc, [id]: { image, name } };
    }, {});
    setImageObj(imagesObj);
  }, [profileRes]);

  useEffect(() => {
    if (response?.acf) {
      const {
        acf: { date, enddate, time_zone, start_time, end_time, default_time_zone },
      } = response;

      if (!date || !start_time || !end_time || !default_time_zone) {
        return;
      }

      let default_timezone = default_time_zone;
      if (typeof default_time_zone === 'object') {
        // eslint-disable-next-line
        default_timezone = Object.keys(default_time_zone).map((id) => {
          return default_time_zone[id];
        })[0];
      }

      const dateS = date.split('/');
      const dateClean = `${dateS[2]}-${dateS[1]}-${dateS[0]}`;
      const dateStart = new Date(`${dateClean}T${start_time}`);
      const dateEnd = new Date(`${dateClean}T${end_time}`);
      const timeZone = default_timezone.split('- ')[1];
      const zonedDateS = utcToZonedTime(dateStart, timeZone);
      const zonedDateE = utcToZonedTime(dateEnd, timeZone);
      const defaultDate = `${format(zonedDateS, TIMEF)}-${format(
        zonedDateE,
        TIMEF
      )} (${default_timezone})`;

      const timesArr = time_zone.slice(0, MAX_ITEMS).map((item) => {
        const timeZoneItem = item.split('- ')[1];
        const zonedDateSi = utcToZonedTime(dateStart, timeZoneItem);
        const zonedDateEi = utcToZonedTime(dateEnd, timeZoneItem);
        return `${format(zonedDateSi, TIMEF)}-${format(zonedDateEi, TIMEF)} (${item})`;
      });

      const timesState = [defaultDate, ...timesArr];
      setTimes(timesState);

      const dateE = enddate.split('/');
      const dateClean2 = `${dateE[2]}-${dateE[1]}-${dateE[0]}`;
      const dateEnds = new Date(`${dateClean2}T${end_time}`);
      let durations = differenceInDays(dateEnds, dateStart);

      if (durations === 1) {
        durations += ` day`;
      } else {
        durations += ` days`;
      }

      if (durations === '0 days') {
        durations = differenceInHours(dateEnds, dateStart);

        if (durations === 1) {
          durations += ` hr`;
        } else {
          durations += ` hrs`;
        }
      }
      setDuration(durations);
    }
  }, [response]);

  if (loading || profileLoad) {
    return (
      <Layout>
        <Loader style={loaderStyle} />
      </Layout>
    );
  }

  const {
    title,
    acf: {
      description,
      event_relationship = [],
      contact_name,
      contact_email,
      category,
      location,
      date,
      contact_picture,
      region: { slug: region },
    },
  } = response;

  const isGlobal = region === 'global';
  const dateS = date.split('/');
  const dateClean = date ? new Date(`${dateS[2]}-${dateS[1]}-${dateS[0]}`) : null;
  const eventLen = event_relationship.length;

  const EditBtn = () => (
    <Edit>
      <Button
        onClick={() =>
          history.push(`/edit-event/${response.id}`, {
            from: `${response.id}`,
          })
        }
      >
        Edit
      </Button>
    </Edit>
  );

  return (
    <Layout>
      <SubHeader breadcrumb={['Events calendar', 'event details']} share Global={isGlobal} />
      {showEditBtn ? <EditBtn /> : null}
      <MastHead>
        <Header>
          <H1>{decode(`${title.rendered}`)}</H1>
          <Category>
            <Capability capabilityId={category} className="eventPage" />
          </Category>
        </Header>
        <Images>
          {eventLen > 0 &&
            event_relationship.map(({ ID }, i, arr) => {
              const id = ID || arr[i];
              console.log(imageObj[id])
              return imageObj[id]?.image ? (
                <ProfPic img={imageObj[id]?.image} width="190" height="190" />
              ) : null;
            })}
        </Images>
        <div className="dateWrapper">
          <span className="date">{date.replaceAll('/', '.')}</span>
          {times.map((time, i) => {
            return (
              // eslint-disable-next-line
              <span className="times" key={time + i}>
                {time}
              </span>
            );
          })}
        </div>
        <Desc>
          <Speakers>
            {eventLen > 0 &&
              event_relationship.map(({ ID }, i, arr) => {
                const id = ID || arr[i];
                return (
                  <span key={imageObj[id]?.name}>
                    {imageObj[id]?.name}
                    {i === arr.length - 1 ? '' : ', '}
                  </span>
                );
              })}
          </Speakers>
          {description}
        </Desc>
      </MastHead>

      <Section>
        <hr />
        <H3>Details and resources</H3>
        <TabSection>
          <Row>
            <Col span={8}>
              <H3>
                Time and
                <br />
                location
              </H3>
            </Col>
            <Col span={16}>
              <Row>
                <Col>{isGlobal ? <ButtonGlobal /> : <ButtonLocal />}</Col>
              </Row>
              <Row>
                <Col span={12}>
                  <span>Location</span>
                  <InnerPosts inner="location" innerId={location} />
                  {!location && '-'}
                </Col>
                <Col span={12}>
                  {duration && (
                    <>
                      <span>Duration</span>
                      {duration}
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <span>Date</span>
                  {dateClean && format(new Date(dateClean), 'EEEE do LLLL yyyy')}
                  {!dateClean && 'TBC'}
                </Col>
                <Col span={12}>
                  <span>Status</span>
                  {dateClean ? 'Scheduled' : 'Planned'}
                </Col>
              </Row>
            </Col>
          </Row>
        </TabSection>
      </Section>

      {contact_name && (
        <Section>
          <hr />
          <Contact name={contact_name} email={contact_email} image={contact_picture} />
        </Section>
      )}

      {dateClean && !isFuture(dateClean) && (
        <Section>
          <Comments id={match.params.id} />
        </Section>
      )}

      {dateClean && !isFuture(dateClean) && (
        <Section>
          <PrintComments id={match.params.id} />
        </Section>
      )}
    </Layout>
  );
};

export default withRouter(Event);
