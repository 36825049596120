/* eslint-disable */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Card from 'components/Card';
import ButtonLoad from 'components/Buttons/buttonLoad';
import Loader from 'components/Loader';
import { H5, P } from 'components/Text';
import { isFuture } from 'date-fns';

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 260px);
  grid-gap: 20px;
  justify-content: space-between;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    display: block;
    > div {
      margin-right: 10px;
      margin-bottom: 10px;
      float: left;
      &:last-child {
        float: none;
      }
    }
  }
  + div {
    clear: both;
  }
`;

const h5 = {
  paddingBottom: '10px',
};

const loaderStyle = {
  margin: '30px auto',
};

const sanatize = (str) => {
  return str.toLowerCase().replace(/\s/g, '');
};

export default function Directory({
  setFilterData,
  locVal,
  specVal,
  eventsVal,
  eventObj,
  searchVal,
  searchLoc,
  searchSpec,
  response,
  loading,
}) {
  const LOAD_LEN = 3;
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const numberOfProfiles = response.length;
  const [visibleCards, setVisibleCards] = useState(0);
  const filteredResults =
    Object.keys(locVal).length ||
    Object.keys(specVal).length ||
    searchVal ||
    Object.keys(eventsVal).length;

  const handleClick = () => {
    if (visibleCards < numberOfProfiles) {
      const more = numberOfProfiles - visibleCards;
      setVisibleCards((prevVisibleCards) => prevVisibleCards + more);
    }
  };

  useEffect(() => {
    setFilterData(response);
    const initialLen = numberOfProfiles <= LOAD_LEN ? numberOfProfiles : LOAD_LEN;
    setVisibleCards(initialLen);
    // eslint-disable-next-line
  }, [response]);

  useEffect(() => {
    const filtering = [];
    let filterData = response;

    if (searchVal) {
      filtering.push({ type: 'search', val: searchVal });
    }

    if (searchLoc) {
      filtering.push({ type: 'searchLoc', val: searchLoc });
    }

    if (searchSpec) {
      filtering.push({ type: 'searchSpec', val: searchSpec });
    }

    if (Object.keys(locVal).length) {
      filtering.push({ type: 'location', val: locVal });
    }

    if (Object.keys(specVal).length) {
      filtering.push({ type: 'specialism', val: specVal });
    }

    if (Object.keys(eventsVal).length) {
      filtering.push({ type: 'events', val: eventsVal });
    }

    if (filtering.length) {
      // eslint-disable-next-line
       filtering.forEach(({ type, val }) => {
        if (type === 'search') {
          filterData = filterData.filter(({ title, acf: { location } }) => {
            return (
              sanatize(title.rendered).includes(sanatize(val)) ||
              location.some(({ slug }) => {
                return slug.includes(sanatize(val));
              })
            );
          });
        }
        if (type === 'searchLoc') {
          filterData = filterData.filter(({ acf: { location } }) => {
            return location.some(({ slug }) => {
              return slug.includes(sanatize(val));
            });
          });
        }
        if (type === 'searchSpec') {
          filterData = filterData.filter(({ acf: { specialism } }) => {
            return specialism.some(({ slug }) => {
              return slug.includes(sanatize(val));
            });
          });
        }

        if (type === 'specialism') {
          const filterArray = Object.keys(val);
          filterData = filterData.filter(({ acf: { specialism } }) => {
            return specialism.some(({ slug }) => {
              return filterArray.includes(slug);
            });
          });
        }
        if (type === 'location') {
          const filterArray = Object.keys(val);
          filterData = filterData.filter(({ acf: { location } }) => {
            return location.some(({ slug }) => {
              return filterArray.includes(slug);
            });
          });
        }
        if (type === 'events') {
          let range;
          let arrRange;

          if (val.includes('-')) {
            // console.log(`with dash: ${val}`);
            range = val.split('-');
            const start = parseInt(range[0], 10);
            const end = parseInt(range[1], 10);
            arrRange = Array(end - start + 1)
              .fill()
              .map((_, idx) => start + idx);
          }

          filterData = filterData.filter(({ lxl_events_extended }) => {
            if (lxl_events_extended === false) {
              if (val === '0') {
                return filterData;
              }
            }
            if (lxl_events_extended) {
              const items = lxl_events_extended;
              const scheduledTotal = items
                .filter((item) => {
                  const dateS = item.date.split('/');
                  const newDate = new Date(`${dateS[2]}-${dateS[1]}-${dateS[0]}`);
                  return isFuture(newDate);
                })
                .map((filteredItem) => {
                  return filteredItem.date;
                });

              if (val === '0') {
                return scheduledTotal.length <= 0;
              }

              if (val === '20+') {
                return scheduledTotal.length && scheduledTotal.length >= 20;
              }
              return scheduledTotal.length && arrRange.includes(scheduledTotal.length);
            }
          });
        }
      });

      setVisibleCards(response.length);
      return setData(filterData);
    }

    return setData(response);
  }, [searchVal, specVal, locVal, eventsVal, eventObj, searchLoc, searchSpec, response]);

  useEffect(() => {
    const sorted = [...data].sort((a, b) => {
      const surnameA = a.title.rendered.split(' ');
      const surnameB = b.title.rendered.split(' ');
      return surnameA[surnameA.length - 1] > surnameB[surnameB.length - 1] ? 1 : -1;
    });

    setSortedData(sorted);
  }, [data]);

  const showViewing = !loading && data.length > 0;

  return (
    <>
      <H5 style={h5}>
        {showViewing && !filteredResults
          ? `Viewing ${visibleCards} of ${numberOfProfiles} profiles`
          : `Viewing filtered results`}
      </H5>
      <CardsWrapper>
        {loading && <Loader style={loaderStyle} />}
        {sortedData.slice(0, visibleCards).map((props) => (
          <Card {...props} key={props.id} />
        ))}
        {!loading && data.length === 0 && <P>No results found</P>}
      </CardsWrapper>
      {!(visibleCards >= numberOfProfiles) && !filteredResults && (
        <ButtonLoad onClick={handleClick} />
      )}
      <br />
    </>
  );
}
