/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Form, Input, Alert, Upload, message } from 'antd';
import { H5, H3, P } from 'components/Text';
import axios from 'axios';
import ls from 'local-storage';
import styled from 'styled-components';
import Button from 'components/Buttons';

const { TextArea } = Input;

const { REACT_APP_CMS_URL } = process.env;

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e && e.fileList;
};

const instance = axios.create({
  baseURL: `${REACT_APP_CMS_URL}/wp-json/jwt-auth/v1/token`,
  headers: { Authorization: `Bearer ${ls.get('session_token')}` },
});

const CommentsWrap = styled.div`
  .ant-input {
    background: #fff !important;
    border: none !important;
    opacity: 1 !important;
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }

  P {
    max-width: 100%;
  }

  h5 {
    padding-bottom: 10px;
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: end;
  .btn-upload {
    background: white !important;
    color: #939597;
  }
`;

export default function Comments(id) {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessagee] = useState('');
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [fileList, setFileList] = useState([]);

  const [form] = Form.useForm();

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const onFinish = (values) => {
    const results = {
      status: 'hold',
      post: `${id.id}`,
      content: values.comment,
    };
    // console.log(selectedFiles);
    instance
      .post(`${REACT_APP_CMS_URL}/wp-json/wp/v2/comments/`, results)
      .then((res) => {
        setSuccessMessage(`Your comment has been sent for approval`);
        const num = res.data.id;
        const n = num.toString();
        const photo = new FormData();
        photo.append('lxl_field_type', 'comment');
        photo.append('object_id', n);
        photo.append('file', selectedFiles);
        return axios.post(`${REACT_APP_CMS_URL}/wp-json/lxl/v1/file/upload`, photo);
      })
      .then(() => {
        form.resetFields();
      })
      .catch((err) => {
        // handle err
        setErrorMessagee(`${err}`);
      });
  };

  const onFinishFailed = () => {
    console.log('Failed:');
  };

  const props = {
    name: 'file',
    multiple: false,
    onChange(info) {
      console.log(info.file.status);
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'removed') {
        setFileList('');
        setSelectedFiles('');
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setFileList(info.fileList);
        setSelectedFiles(info.fileList[0].originFileObj);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <CommentsWrap>
      <hr />
      <H3>Comments and feedback</H3>
      <P>
        Have your participated in this event? Share your experience with others, leave your comments
        or feedback.
      </P>
      <H5>Please remember that your comment will be displayed publicly.</H5>

      {successMessage && <Alert message={successMessage} type="success" />}
      {errorMessage && <Alert message={errorMessage} type="error" />}

      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item name="comment" message="add comment" rules={[{ required: true }]}>
          <TextArea rows={4} allowClear placeholder="Start typing here..." />
        </Form.Item>
        <ButtonsWrap>
          <Form.Item name="upload" valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload {...props} customRequest={dummyRequest} name="logo">
              {fileList.length < 1 && <Button className="btn-upload">Click to upload</Button>}
            </Upload>
          </Form.Item>
          <Form.Item labelAlign="left">
            <Button htmlType="submit">Share comment</Button>
          </Form.Item>
        </ButtonsWrap>
      </Form>
    </CommentsWrap>
  );
}
