import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { format } from 'date-fns';
import styled from 'styled-components';
import useFetch from 'services/hooks';
import Loader from 'components/Loader';
import { H4, H5, P } from 'components/Text';
import Button from 'components/Buttons';
import { fonts } from 'pages/variables';
import ProfPic from 'components/ProfilePic/profilePic';

const Comments = styled.div`
  .commentBlock {
    margin-bottom: 35px;
  }

  .avatar {
    width: 80px;
    margin-right: 10px;
    margin-bottom: 7px;
  }

  h4 {
    padding-bottom: 10px;
    line-height: 1;
    font-size: 21px;
  }

  p {
    margin-bottom: 0;
  }

  .dates {
    font-size: 15px;
    padding-bottom: 2px;
    font-family: ${fonts.SpaceMono};

    &:first-child {
      padding-bottom: 7px;
    }
  }

  h4 {
    font-family: ${fonts.PlayFair};
  }

  h5 {
    margin-bottom: 20px;
  }

  svg {
    margin-right: 10px;
  }
`;

const Comment = styled.div`
  margin: 20px 0;
`;

const HRSlim = styled.hr`
  border: 0px;
  border-top: 1px solid #dcdcdd;
  margin-top: 20px !important;
`;

const number = (x) => {
  return parseInt(x, 0);
};

const PrintComments = (id) => {
  const [{ response, loading }] = useFetch(`comments?lxl_per_page=100`);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const commentsArr = response.filter((comment) => {
      return comment.post === number(id.id);
    });
    setComments(commentsArr);
  }, [response, id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Comments>
      <HRSlim />
      {!!comments.length && <H5>Viewing {comments.length} comments</H5>}
      {comments.map((comment) => {
        const commentDate = new Date(comment.date);
        return (
          <div className="commentBlock" key={comment.id}>
            <Row>
              <Col>
                <ProfPic img={comment.author_avatar_urls[96]} width="74" height="74" />
              </Col>
              <Col>
                <H4>{comment.author_name}</H4>
                <P className="dates">{comment.date && format(commentDate, 'do LLLL yyyy')}</P>
                <P className="dates">{comment.date && format(commentDate, 'hh.mm')}</P>
              </Col>
            </Row>

            <Comment dangerouslySetInnerHTML={{ __html: comment.content.rendered }} />

            {comment.acf.comment_media_file && (
              <Button>
                <a href={comment.acf.comment_media_file} download>
                  Download
                </a>
              </Button>
            )}
            <HRSlim />
          </div>
        );
      })}
    </Comments>
  );
};

export default PrintComments;
