/**
 * Simple sanity check that the email address is "most likely" well-formed,
 * before saving to the post's metadata - does not verify or sanitize:
 *
 * @param {string} str - string to test for email-like appearance
 * @return {bool}
 */
export default function isValidEmail(str) {
  if (typeof str !== 'string') {
    return false;
  }
  if (str.trim().length === 0) {
    return false;
  }
  if (!str.match(/\S+@\S+\.\S+/)) {
    return false;
  }
  if (str.indexOf(' ') !== -1 || str.indexOf('..') !== -1) {
    return false;
  }
  return true;
}
