import React from 'react';
import useFetch from 'services/hooks';
import styled from 'styled-components';
import Loader from 'components/Loader';
import { H3 } from 'components/Text';

const loaderStyle = {
  margin: '150px auto',
};

const Icon = styled.div`
  border: 1px solid #dcdcdd;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: flex;
  img {
    width: 100%;
  }
`;

const VirtualCapability = styled.div`
  display: flex;
  align-items: center;
  h3 {
    margin-bottom: 0px !important;
    margin-top: 5px;
  }
  &.eventPage {
    div {
      order: 2;
      width: 50px;
      height: 50px;
    }
    h3 {
      order: 1;
      margin-right: 10px;
      font-size: 24px !important;
    }
  }
`;

export default function Capability({ capabilityId, className }) {
  const [{ response, loading }] = useFetch(`category/${capabilityId}`);

  if (loading) {
    return <Loader style={loaderStyle} />;
  }

  const post = response;

  return (
    <VirtualCapability className={className}>
      {post.acf.length !== 0 && (
        <Icon>
          <img src={post.acf.icon.url} alt={post.acf.icon.ID} />
        </Icon>
      )}
      <H3 style={{ fontSize: 15, padding: 0 }}>
        {post.name}
        <br />
      </H3>
    </VirtualCapability>
  );
}
