import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Button from 'components/Buttons';
import Text from 'components/Text';
import Loader from 'components/Loader';
import Input from 'components/Input';
import IconClose from 'components/Icons/icon-close';
import { colors, fonts, sizes } from 'pages/variables';

const Form = styled.form`
  padding: 0 20px;

  input[type='text'] {
    width: 180px !important;
  }

  .close {
    position: absolute;
    right: 26px;
    top: 23px;
    z-index: 1;
    outline: 0;
  }
`;

const FooterCallout = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  button {
    margin-left: 10px;
  }
`;

const Label = styled.label`
  margin: 10px 0;
  display: flex;
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
  }
  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 2px solid #555555;
    border-radius: 3px;
    background-color: white;
  }
  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  }
  span {
    padding-left: 10px;
  }
`;

const loaderStyle = {
  margin: '20px',
};

const getUniqueItems = (data, type) => {
  let itemArr = [];
  data.forEach(({ acf }) => {
    itemArr = [...itemArr, ...acf[type]];
  });

  itemArr.sort((a, b) => {
    return a.count > b.count ? -1 : 1;
  });

  const uniqueArr = [...new Map(itemArr.map((item) => [item.slug, item])).values()];

  const slicedArr = uniqueArr
    .map(({ name, slug }) => {
      return {
        val: name,
        key: slug,
      };
    })
    .slice(0, 6)
    .sort((a, b) => {
      return a.val > b.val ? 1 : -1;
    });

  return slicedArr;
};

const eventCheckBoxes = ['0', '1-5', '5-10', '10-20', '20+'];

const Filter = ({
  onClose,
  loc,
  setLoc,
  spec,
  setSpec,
  loadingSpec,
  loadingLoc,
  local,
  setSearchLoc,
  setSearchSpec,
  searchSpec,
  searchLoc,
  events,
  setEvents,
}) => {
  const [checkedItemsLoc, setCheckedItemsLoc] = useState({});
  const [checkedItemSpec, setCheckedItemSpec] = useState({});
  const [localLocSearch, setLocalLocSearch] = useState(searchLoc);
  const [localSpecSearch, setLocalSpecSearch] = useState(searchSpec);
  const [checkedItemEvent, setCheckedItemEvent] = useState({});
  const [specList, setSpecList] = useState([]);
  const [locList, setLocList] = useState([]);
  const { handleSubmit, reset } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    setSpecList(getUniqueItems(local, 'specialism'));
    setLocList(getUniqueItems(local, 'location'));
    setCheckedItemEvent(events);
  }, [loc, spec, events, local]);

  const handleChangeLoc = (event) => {
    const { checked, name } = event.target;
    if (checked) {
      const checkedObj = {
        ...checkedItemsLoc,
        [name]: checked,
      };
      setCheckedItemsLoc(checkedObj);
    } else {
      const checkedObjRemove = {
        ...checkedItemsLoc,
      };
      delete checkedObjRemove[name];
      setCheckedItemsLoc(checkedObjRemove);
    }
  };

  const handleChangeSpec = (event) => {
    const { checked, name } = event.target;
    if (checked) {
      const checkedObj = {
        ...checkedItemSpec,
        [name]: checked,
      };
      setCheckedItemSpec(checkedObj);
    } else {
      const checkedObjRemove = {
        ...checkedItemSpec,
      };
      delete checkedObjRemove[name];
      setCheckedItemSpec(checkedObjRemove);
    }
  };

  useEffect(() => {
    setCheckedItemsLoc(loc);
    setCheckedItemSpec(spec);
    setCheckedItemEvent(events);
  }, [loc, spec, events]);

  const handleSubmitForm = () => {
    setLoc(checkedItemsLoc);
    setSpec(checkedItemSpec);
    setSearchLoc(localLocSearch);
    setSearchSpec(localSpecSearch);
    setEvents(checkedItemEvent);
    onClose();
  };

  const clearForm = () => {
    reset();
    setLoc('');
    setSpec('');
    setLocalLocSearch('');
    setLocalSpecSearch('');
    setSearchLoc('');
    setSearchSpec('');
    setEvents('');
  };

  const close = () => {
    onClose();
  };

  return (
    <Form onSubmit={handleSubmit()}>
      <button type="button" onClick={close} className="close">
        <IconClose />
      </button>
      <Row>
        <Col span={8}>
          <Text color={colors.primary} size={sizes.size20} font={fonts.KumBold}>
            Location
          </Text>
          {loadingLoc && <Loader style={loaderStyle} />}
          {locList.map(({ key, val }) => {
            return (
              <Label key={key}>
                <input
                  type="checkbox"
                  name={key}
                  value={val}
                  onChange={handleChangeLoc}
                  checked={checkedItemsLoc[key] === true}
                />
                <span>{val}</span>
              </Label>
            );
          })}
          {Array.from(Array(6 - locList.length).keys()).map((key) => {
            return <div key={key} style={{ height: '20px', margin: '10px 0' }} />;
          })}
          <Input
            placeholder="Search Locations"
            type="text"
            value={localLocSearch}
            onChange={(e) => {
              setLocalLocSearch(e.target.value);
            }}
          />
        </Col>
        <Col span={8}>
          <Text color={colors.primary} size={sizes.size20} font={fonts.KumBold}>
            Specialism
          </Text>
          {loadingSpec && <Loader style={loaderStyle} />}
          {specList.map(({ key, val }) => {
            return (
              <Label key={key}>
                <input
                  type="checkbox"
                  name={key}
                  value={val}
                  onChange={handleChangeSpec}
                  checked={checkedItemSpec[key] === true}
                />
                <span>{val}</span>
              </Label>
            );
          })}
          {Array.from(Array(6 - specList.length).keys()).map((key) => {
            return <div key={key} style={{ height: '20px', margin: '10px 0' }} />;
          })}
          <Input
            placeholder="Search Specialism"
            type="text"
            value={localSpecSearch}
            onChange={(e) => {
              setLocalSpecSearch(e.target.value);
            }}
          />
        </Col>
        <Col span={8}>
          <Text color={colors.primary} size={sizes.size20} font={fonts.KumBold}>
            Scheduled events
          </Text>
          {eventCheckBoxes.map((item) => {
            return (
              <Label key={item}>
                <input
                  type="checkbox"
                  name={item}
                  value={item}
                  onChange={(event) => {
                    const { name } = event.target;
                    setCheckedItemEvent(name);
                  }}
                  checked={checkedItemEvent === item}
                />
                <span>{item}</span>
              </Label>
            );
          })}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FooterCallout>
            <Button onClick={handleSubmitForm}>Submit</Button>
            <Button onClick={clearForm}>Clear</Button>
          </FooterCallout>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
