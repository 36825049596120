import React from 'react';
import useFetch from 'services/hooks';

import { Form, Select } from 'antd';

const DropdownSelect = ({
  name,
  type,
  placeholder,
  label,
  single,
  required = true,
  message,
  onChange,
}) => {
  const [{ response }] = useFetch(`${type === 'profiles' ? type+'?lxl_per_page=100' : type+'?per_page=100'}`);

  const post = response;

  const { Option } = Select;
  console.log(type);
  const rules = {
    required,
    message: `${message}`,
  };

  if (!single) {
    rules.type = 'array';
  }

  return (
    <Form.Item name={name} label={label || `Select ${type}`} rules={[rules]} labelAlign="left">
      {!single ? (
        <Select
          mode="multiple"
          placeholder={placeholder || `Please select ${type}`}
          onChange={onChange}
        >
          {post.map((acf) => {
            const { id, description } = acf;
            const itemId = acf.id || id;
            const value = `${acf.name || acf.title?.rendered}-${itemId}`;
            return (
              <Option key={itemId} value={value}>
                {acf.name || acf.title?.rendered}
                <br />
                <span>{description}</span>
              </Option>
            );
          })}
        </Select>
      ) : (
        <Select placeholder={placeholder || `Please select ${type}`} onChange={onChange}>
          {post.map((acf) => {
            const { id } = acf;
            const itemId = acf.id || id;
            const value = `${acf.name || acf.title?.rendered}-${itemId}`;
            return (
              <Option key={itemId} value={value}>
                {acf.name || acf.title?.rendered}
              </Option>
            );
          })}
        </Select>
      )}
    </Form.Item>
  );
};

export default DropdownSelect;
