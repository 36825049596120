import React from 'react';
import styled from 'styled-components';
import useFetch from 'services/hooks';
import Layout from 'components/Layout/layout';
import Text, { H1, P } from 'components/Text';
import { Col, Row } from 'antd';
import { colors, fonts, sizes } from 'pages/variables';
import ButtonAdd from 'components/Buttons/buttonAdd';
import Loader from 'components/Loader';
import Team from 'components/Team';
import aboutUsImg from '../../assets/img/about-graphic.png';
import IconAssets from '../../components/Icons/icon-assets';
import IconMundi from '../../components/Icons/icon-mundi';

const loaderStyle = {
  margin: '150px auto',
};

const About = styled.div`
  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .col-resources {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .wrapCard {
    height: 100%;
  }
`;

const Card = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    display: block;
    .Btn-learnMore {
      height: 140px;
      display: flex;
      align-items: flex-end;
    }
  }
  &.noPad {
    padding: 0;
  }
`;

const PlaceHolder = styled.div`
  background: #a9aaad;
  width: 100%;
  height: 150px;
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 150px;
    height: 150px;
  }
`;

const Quote = styled.div`
  background: ${colors.primary};
  width: 100%;
  height: 100%;
  border-radius: 0 10px 10px 0;
  background: rgb(196, 48, 77);
  background: linear-gradient(180deg, rgba(196, 48, 77, 1) 0%, rgba(150, 31, 101, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    color: ${colors.white};
    width: 270px;
  }

  span.text {
    font-size: 32px;
    font-family: ${fonts.KumBold};
    line-height: 1.2;
    padding-bottom: 20px;
  }
`;

const buttonStyles = {
  marginTop: '40px',
};

const colStyles = { padding: '20px 100px 20px 20px' };

const normalise = { lineHeight: 1.1, paddingBottom: '20px' };

const Title = ({ children }) => (
  <Text
    color={colors.primary}
    size={sizes.size24}
    font={fonts.KumBold}
    style={{ paddingBottom: 10, lineHeight: '1.3rem' }}
  >
    {children}
  </Text>
);

const SubTitle = ({ children }) => (
  <Text size={sizes.size32} font={fonts.InterBlack} style={normalise}>
    {children}
  </Text>
);

const LargeTitle = ({ children }) => (
  <Text size="50px" font={fonts.InterBlack} style={normalise}>
    {children}
  </Text>
);

const AboutUs = () => {
  const [{ response, loading }] = useFetch('assets?lxl_per_page=500');

  if (loading) {
    return <Loader style={loaderStyle} />;
  }

  const post = response;

  return (
    <Layout>
      <About>
        <H1>About us</H1>
        <P>
          Welcome to the About Us section. Here you can ﬁnd out about the team, learn more about the
          KNOW brand and download the relevant assets.
        </P>
        <hr />

        <Row style={{ marginBottom: 36 }} gutter={36}>
          <Col span={16}>
            <Card style={{ justifyContent: 'flex-start' }}>
              <Title>Our vision</Title>
              <LargeTitle>
                KNOW is Astellas’ world-class HCP educational program with one aim - to improve
                patient outcomes.
              </LargeTitle>
              <P>
                By educating those entrusted with their care, we will better support men (and their
                loved ones) living with prostate cancer. KNOW brings together local, national, and
                international KEEs in partnership with Astellas’ global and local teams to offer
                easy access to the latest research, real-world evidence, innovative ideas, and best
                clinical practice. And in doing so, KNOW will help establish Astellas as the trusted
                partner.
              </P>
            </Card>
          </Col>
          <Col span={8}>
            <Card className="wrapCard">
              <div>
                <Title>Initiatives</Title>
                <img
                  src={aboutUsImg}
                  alt=""
                  style={{ width: '82%', paddingBottom: '30px', display: 'flex', margin: '0 auto' }}
                />
                <SubTitle>A truly global education program.</SubTitle>
              </div>
              <div className="Btn-learnMore">
                <ButtonAdd styles={buttonStyles}>Learn more</ButtonAdd>
              </div>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginBottom: 36 }}>
          <Col span={24}>
            <Card className="noPad">
              <Row>
                <Col span={12} style={colStyles}>
                  <Title>Our purpose</Title>
                  <SubTitle>“Knowledge Now”</SubTitle>
                  <P>
                    Developed in collaboration with the world’s leading experts, KNOW offers
                    high-value education and learning opportunities. KNOW is shorthand for
                    “Knowledge Now”.
                  </P>
                  <P>
                    KNOW addresses unmet educational need by converting science into value
                    for patients in areas where cutting-edge science and technology are poised to
                    drive innovation.
                  </P>
                  <P>
                    KNOW is for oncologists, urologists, radiation oncologists, specialist nurses,
                    involved in the treatment and care of people with prostate cancer who want to
                    feel informed, motivated, and empowered to make a real difference to patients’
                    lives.
                  </P>
                </Col>

                <Col span={12}>
                  <Quote>
                    <span className="text">
                      Our purpose is our passion - to better support prostate cancer patients, by
                      educating those entrusted with their care.
                    </span>
                  </Quote>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row style={{ marginBottom: 36 }}>
          <Col span={24}>
            <Title>Meet the team</Title>
            <Team />
          </Col>
        </Row>

        <Row style={{ marginBottom: 36 }}>
          <Col span={24}>
            <Card>
              <Title>Resources</Title>
              <P>
                You can download the brand guidance, creative asset templates and global taskforce
                meeting documents below. If you require any further information, please contact the
                team.
              </P>

              <Row style={{ marginBottom: 36 }} gutter={20}>
                {post.map(
                  (item1) =>
                    item1.slug === 'brand-guidance' && (
                      <Col className="col-resources" span={8} key={item1.slug}>
                        <div>
                          <PlaceHolder>
                            <IconAssets assetName={item1.acf.type_file} />
                          </PlaceHolder>
                          <Title>Brand guidance</Title>
                          <P>
                            Download the KNOW brand guidelines to learn about the key messages and
                            visual identity
                          </P>
                        </div>
                        <a
                          href={item1.acf.asset_file}
                          alt="Brand guidance"
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <div>
                            <ButtonAdd styles={buttonStyles}>Download</ButtonAdd>
                          </div>
                        </a>
                      </Col>
                    )
                )}
                {post.map(
                  (item2) =>
                    item2.slug === 'creative-assets' && (
                      <Col className="col-resources" span={8} key={item2.slug}>
                        <div>
                          <PlaceHolder>
                            <IconAssets assetName={item2.acf.type_file} />
                          </PlaceHolder>
                          <Title>Creative assets</Title>
                          <P>
                            Download the KNOW logos, PowerPoint, factsheet and leaflet templates
                          </P>
                        </div>
                        <a
                          href={item2.acf.asset_file}
                          alt="Creative assets"
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <div>
                            <ButtonAdd styles={buttonStyles}>Download</ButtonAdd>
                          </div>
                        </a>
                      </Col>
                    )
                )}
                {post.map(
                  (item3) =>
                    item3.slug === 'global-task-force' && (
                      <Col className="col-resources" span={8} key={item3.slug}>
                        <div>
                          <PlaceHolder>
                            <IconMundi fill="#fff" />
                          </PlaceHolder>
                          <Title>Global taskforce &amp; newsletters</Title>
                          <P>Download the global taskforce meeting slides, pre-reads and KNOW newsletters</P>
                        </div>

                        <a
                          href={item3.acf.asset_file}
                          alt="Global task force"
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <div>
                            <ButtonAdd styles={buttonStyles}>Download</ButtonAdd>
                          </div>
                        </a>
                      </Col>
                    )
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </About>
    </Layout>
  );
};

export default AboutUs;
