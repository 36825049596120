import React from 'react';
import useFetch from 'services/hooks';

import { Form, Select } from 'antd';

const DropdownSelectEngagement = ({
  name,
  type,
  placeholder,
  label,
  single,
  required = true,
  message,
  onChange,
}) => {
  const [{ response }] = useFetch(`${type}?lxl_per_page=100`);

  const post = response;

  const { Option } = Select;

  const rules = {
    required,
    message: `${message}`,
    validator: (rule, value, callback) => {
      if (value) {
        if (value.length > 1) {
          callback('No more than 1 tags');
        } else if (value.length <= 1) {
          callback();
        }
      }
    },
  };

  if (!single) {
    rules.type = 'array';
  }

  return (
    <Form.Item
      name={name}
      label={label || `Select ${type}`}
      rules={[{ required: true, message: `${message}` }]}
      labelAlign="left"
    >
      <Select placeholder={placeholder || `Please select ${type}`} onChange={onChange}>
        {post.map((acf) => {
          const { id } = acf;
          const itemId = acf.id || id;
          const value = `${acf.name || acf.title?.rendered}-${itemId}`;
          return (
            <Option key={itemId} value={value}>
              {acf.name || acf.title?.rendered}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default DropdownSelectEngagement;
