/**
 * Simple sanity check that the URL is "most likely" well-formed,
 * Uses HTML5 Input type = "url" for validation fn.
 * (We can't use the HTML 'URL' constructor here, because we have to support IE11.)
 *
 * @param {string} str - string to test for url-like appearance
 * @return {bool}
 */
export default function isValidURL(str) {
  const elem = document.createElement('input');
  elem.setAttribute('type', 'url');
  elem.value = str;
  return elem.validity.valid;
}
